<template>
  <div class="container pb-100">
    <div
      class="cta-wrap"
      data-aos="fade-in"
      data-aos-duration="1200"
      data-aos-delay="400"
    >
      <div class="row align-items-center">
        <div class="col-xl-2 col-lg-3">
          <div class="cta-img-one moveHorizontal">
            <img src="../../assets/img/shape-2.webp" alt="Image" />
          </div>
        </div>
        <div class="col-xl-8 col-lg-6">
          <div class="content-title style-one text-center">
            <h2>Connect with us today</h2>
            <p>Let us help you create unforgettable dining memories</p>
            <router-link to="/contact" class="btn-one">Contact Us</router-link>
          </div>
        </div>
        <div class="col-xl-2 col-lg-3">
          <div class="cta-img-two bounce">
            <img src="../../assets/img/shape-3.webp" alt="Image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BecomePartner",
};
</script>

<style lang="scss" scoped>
.cta-wrap {
  background-color: #efeae4;
  border-radius: 20px;
  padding: 60px 50px;
  .content-title {
    a {
      margin-top: 26px;
    }
    p {
      margin: 12px 0 0;
    }
  }
  .cta-img-two {
    display: block;
    img {
      display: block;
      margin-left: auto;
    }
  }
}

@media only screen and (max-width: 767px) {
  .cta-wrap {
    padding: 30px 20px;
    .content-title {
      margin: 20px 0;
    }
  }
}
@media only screen and (max-width: 991px) {
  .cta-wrap {
    .content-title {
      margin: 20px 0;
    }
  }
}
@media only screen and (min-width: 1400px) {
  .cta-wrap {
    padding: 47px 125px 52px 109px;
    .cta-img-one {
      position: relative;
      top: 19px;
      left: 5px;
    }
    .cta-img-two {
      position: relative;
      top: -5px;
    }
    .content-title {
      padding-left: 30px;
      p {
        margin-top: 10px;
      }
    }
  }
}
</style>