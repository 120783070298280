<template>
  <div class="container ptb-100">
    <ul class="menu-tag-list list-style mb-4">
      <li v-for="list in lists" :key="list.id">
        <router-link to="/menu-classic">
          {{ list.title }}
          <img :src="list.image" alt="Image" />
        </router-link>
      </li>
    </ul>
    <router-link to="/menu-classic" class="link-one">
      View Menu
      <img src="../../assets/img/icons/up-right-arrow.svg" alt="Image" />
    </router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "TagList",
  data() {
    return {
      lists: [
        {
          id: 1,
          title: "Main Dishes",
          image: require("../../assets/img/tags/burger.webp"),
        },
        {
          id: 2,
          title: "Cold Coffees",
          image: require("../../assets/img/tags/hot-dogs.webp"),
        },
        {
          id: 3,
          title: "Hot Coffees",
          image: require("../../assets/img/tags/chicken-nuggets.webp"),
        },
        {
          id: 4,
          title: "Mojitos",
          image: require("../../assets/img/tags/sandwiches.webp"),
        },
        {
          id: 5,
          title: "Cold Drinks",
          image: require("../../assets/img/tags/fish-chips.webp"),
        },
        {
          id: 6,
          title: "Hot Drinks",
          image: require("../../assets/img/tags/french-fries.webp"),
        },
      ],
    };
  },
});
</script>

<style lang="scss" scoped>
.menu-tag-list {
  li {
    display: inline-block;
    margin-right: 8px;
    padding-right: 40px;
    position: relative;
    &:last-child {
      margin-right: 0;
      padding-right: 0;
      &:after {
        display: none;
      }
    }
    &:after {
      position: absolute;
      top: 40px;
      right: 0;
      content: "/";
      font-size: 64px;
      color: var(--whiteColor);
    }
    a {
      color: var(--titleColor);
      font-size: 44px;
      line-height: 90px;
      letter-spacing: 1.08px;
      img {
        margin-left: 10px;
        position: relative;
        top: -4px;
      }
    }
  }
}

.link-one{
  color: #A4AE46;
}
@media only screen and (max-width: 767px) {
  .menu-tag-list {
    li {
      padding-right: 30px;
      &:after {
        top: 8px;
        right: 0;
        font-size: 22px;
      }
      a {
        font-size: 18px;
        line-height: 40px;
        img {
          max-width: 25px;
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .menu-tag-list {
    li {
      &:after {
        top: 20px;
        right: 0;
        font-size: 40px;
      }
      a {
        font-size: 34px;
        line-height: 60px;
        img {
          max-width: 60px;
        }
      }
    }
  }
}
</style>