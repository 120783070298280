<template>
  <div class="bg_primary filter-wrap style-one ptb-100">
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <ul class="nav nav-tabs menu-tablist style-one" role="tablist">
            <li class="nav-item">
              <button
                class="nav-link active"
                data-bs-toggle="tab"
                data-bs-target="#tab_1"
                type="button"
                role="tab"
              >
              Main Dishes
              </button>
            </li>
            <li class="nav-item">
              <button
                class="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#tab_2"
                type="button"
                role="tab"
              >
              Cold Coffees
              </button>
            </li>
            <li class="nav-item">
              <button
                class="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#tab_3"
                type="button"
                role="tab"
              >
              Hot Coffees
              </button>
            </li>
            <li class="nav-item">
              <button
                class="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#tab_4"
                type="button"
                role="tab"
              >
              Mojitos
              </button>
            </li>
            <li class="nav-item">
              <button
                class="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#tab_5"
                type="button"
                role="tab"
              >
              Hot Drinks
              </button>
            </li>
            <li class="nav-item">
              <button
                class="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#tab_6"
                type="button"
                role="tab"
              >
              Cool Drinks
              </button>
            </li>
          </ul>
        </div>
        <div class="col-lg-9">
          <div class="tab-content menu-tab-content">
            <div class="tab-pane fade show active" id="tab_1" role="tabpanel">
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-13.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                     
                        Cheese Rolls
                    </h3>
                    <p>
                      Cheese roll sticks, 10 pcs
                    </p>
                  </div>
                  <div class="food-price">
                    <h4>40 QR</h4>
                  
                  </div>
                </div>
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-14.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                    
                        Classic Cabbage medium size
                        
                     
                    </h3>
                    <p>
                      Stuffed cabbage leaves, pomegranate, with special sauce, 60 pcs
                    </p>
                  </div>
                  <div class="food-price">
                    <h4>150 QR</h4>
                  
                  </div>
                </div>
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-15.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                    
                        Classic Cabbage small size
                       
                     
                    </h3>
                    <p>
                      Stuffed cabbage leaves, pomegranate, with special sauce, spicy, 20 pcs
                    </p>
                  </div>
                  <div class="food-price">
                    <h4>50 QR</h4>
                   
                  </div>
                </div>
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-22.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                     
                        Classic Vine leaves medium size
                    
                    
                    </h3>
                    <p>
                      Pomegranate molasses, lemon, 60pcs
                    </p>
                  </div>
                  <div class="food-price">
                    <h4>150 QR</h4>
                   
                  </div>
                </div>
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-26G.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                     
                        Classic Vine leaves small size
                       
                      
                    </h3>
                    <p>
                      Pomegranate molasses, lemon, 20pcs
                    </p>
                  </div>
                  <div class="food-price">
                    <h4>50 QR</h4>
                  </div>
                </div>
                
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-23.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                     
                        Crispy Waraq Enab Roll (Normal)
                        
                   
                    </h3>
                    <p>
                      Stuffed vine leaves roll, 15 pcs
                    </p>
                  </div>
                  <div class="food-price">
                    <h4>50 QR</h4>
                   
                  </div>
                </div>
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-24.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                     
                        Crispy Waraq Enab Roll (Spicy)
                    
                    </h3>
                    <p>
                      Stuffed vine leaves roll, spicy, 15 pcs
                    </p>
                  </div>
                  <div class="food-price">
                    <h4>50 QR</h4>
                   
                  </div>
                </div>
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-25.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                    
                        Musakhan
                       
                    
                    </h3>
                    <p>
                      Wrapped shredded chicken roll, sumac, yogurt, 15 pcs

                    </p>
                  </div>
                  <div class="food-price">
                    <h4>30 QR</h4>
                   
                  </div>
                </div>
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-26.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                     
                        Normal Stuffed Onion medium size
                       
                  
                    </h3>
                    <p>
                      Stuffed onion, spicy, 45 pcs
                    </p>
                  </div>
                  <div class="food-price">
                    <h4>150 QR</h4>
                  </div>
                </div>
                
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-26A.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                    
                        Normal Stuffed Onion small size
                    
                    </h3>
                    <p>
                      Stuffed onion, spicy, 15 pcs
                    </p>
                  </div>
                  <div class="food-price">
                    <h4>50 QR</h4>
                  </div>
                </div>
                
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-26B.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                    
                        Spicy Cabbage medium size
                       
                      
                    </h3>
                    <p>
                      Stuffed cabbage leaves, pomegranate, with special sauce, spicy, 60 pcs
                    </p>
                  </div>
                  <div class="food-price">
                    <h4>150 QR</h4>
                  </div>
                </div>
                
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-26J.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                     
                        Spicy Cabbage small size
                       
                    
                    </h3>
                    <p>
                      Stuffed cabbage leaves, pomegranate, with special sauce, spicy, 20 pcs
                    </p>
                  </div>
                  <div class="food-price">
                    <h4>50 QR</h4>
                  </div>
                </div>
                
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-26C.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                     
                        Spicy Stuffed Onion medium size
                       
                     
                    </h3>
                    <p>
                      Stuffed onion, spicy, 45 pcs
                    </p>
                  </div>
                  <div class="food-price">
                    <h4>150 QR</h4>
                  </div>
                </div>
                
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-26D.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                     
                        Spicy Stuffed Onion small size
                       
                     
                    </h3>
                    <p>
                      Stuffed onion, spicy, 15 pcs
                    </p>
                  </div>
                  <div class="food-price">
                    <h4>50 QR</h4>
                  </div>
                </div>
                
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-26E.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                     
                        Spicy Vine leaves medium size
                       
                     
                    </h3>
                    <p>
                     Pomegranate molasses, lemon, spicy,  60pcs
                    </p>
                  </div>
                  <div class="food-price">
                    <h4>150 QR</h4>
                  </div>
                </div>
                
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-26F.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                     
                        Spicy Vine leaves small size
                       
                    
                    </h3>
                    <p>
                      Pomegranate molasses, lemon, spicy,  20pcs
                    </p>
                  </div>
                  <div class="food-price">
                    <h4>50 QR</h4>
                  </div>
                </div>
                
              </div>
              
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-26H.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                     
                        Fatta Waraq Enab with Chips Oman
                       
                    
                    </h3>
                    <p>
                      Stuffed vine leaves, Fatta, yogurt, with chips Oman

                    </p>
                  </div>
                  <div class="food-price">
                    <h4>40 QR</h4>
                  </div>
                </div>
                
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-26I.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                      <router-link to="/food-details">
                        Fatta Waraq Enab with Takis
                       
                      </router-link>
                    </h3>
                    <p>
                      Stuffed vine leaves, Fatta, yogurt, with Takis chips
                    </p>
                  </div>
                  <div class="food-price">
                    <h4>40 QR</h4>
                  </div>
                </div>
                
              </div>
              
            </div>
            <div class="tab-pane fade" id="tab_2" role="tabpanel">
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-27.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                     
                        Ice Americano
                       
                     
                    </h3>
                    <p>
                     
                    </p>
                  </div>
                  <div class="food-price">
                    <h4>22 QR</h4>
                    
                  </div>
                </div>
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-28.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                     
                        Ice Caramel Latte
                     
                    
                    </h3>
                    
                  </div>
                  <div class="food-price">
                    <h4>33 QR</h4>
                   
                  </div>
                </div>
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-29.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                     
                        Ice Flat White
                      
                     
                    </h3>
                   
                  </div>
                  <div class="food-price">
                    <h4>26 QR</h4>
                  
                  </div>
                </div>
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-30.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                     
                        Ice Latte
                        
                   
                    </h3>
                    
                  </div>
                  <div class="food-price">
                    <h4>26 QR</h4>
                   
                  </div>
                </div>
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-31.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                     
                        Ice Mocha
                       
                     
                    </h3>
                    
                  </div>
                  <div class="food-price">
                    <h4>33 QR</h4>
                  
                  </div>
                </div>
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-32.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                    
                        Ice Salted Caramel
                        
                    
                    </h3>
                    
                  </div>
                  <div class="food-price">
                    <h4>33 QR</h4>
                  
                  </div>
                </div>
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-33.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                    
                        Ice Spanish Latte
                     
                    </h3>
                    
                  </div>
                  <div class="food-price">
                    <h4>28 QR</h4>
                  
                  </div>
                </div>
              </div>
             
            </div>
            <div class="tab-pane fade" id="tab_3" role="tabpanel">
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-55.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                    
                        Americano
                        
                     
                    </h3>
                  
                  </div>
                  <div class="food-price">
                    <h4>19 QR</h4>
                   
                  </div>
                </div>
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-56.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                     
                        Café Latte
                     
                    </h3>
                   
                  </div>
                  <div class="food-price">
                    <h4>26 QR</h4>
                  </div>
                </div>
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-57.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                     
                        Café Mocha
                      
                    </h3>
                    
                  </div>
                  <div class="food-price">
                    <h4>30 QR</h4>
                  </div>
                </div>
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-58.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                      
                        Cappuccino
                     
                    </h3>
                    
                  </div>
                  <div class="food-price">
                    <h4>24 QR</h4>
                  </div>
                </div>
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-59.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                      
                        Cortado
                     
                    </h3>
                   
                  </div>
                  <div class="food-price">
                    <h4>20 QR</h4>
                  </div>
                </div>
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-60.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                     
                        Espresso
                      
                    </h3>
                    
                  </div>
                  <div class="food-price">
                    <h4>18 QR</h4>
                  </div>
                </div>
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-61.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                      
                        Flat White
                    
                    </h3>
                  
                  </div>
                  <div class="food-price">
                    <h4>24 QR</h4>
                   
                  </div>
                </div>
              </div>

              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-61A.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                     
                        Matcha Latte
                     
                    </h3>
                    
                  </div>
                  <div class="food-price">
                    <h4>25 QR</h4>
                   
                  </div>
                </div>
              </div>

              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-61B.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                      
                        Salted Caramel Latte
                    
                    </h3>
                    
                  </div>
                  <div class="food-price">
                    <h4>34 QR</h4>
                   
                  </div>
                </div>
              </div>

               <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-61C.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                      
                        Spanish Latte
                    
                    </h3>
                    
                  </div>
                  <div class="food-price">
                    <h4>28 QR</h4>
                   
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="tab_4" role="tabpanel">
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-62.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                     
                        Blueberry Mint
                       
                     
                    </h3>
                    
                  </div>
                  <div class="food-price">
                    <h4>28 QR</h4>
                   
                  </div>
                </div>
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-63.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                   
                        Cranberry
                    
                    </h3>
                    
                  </div>
                  <div class="food-price">
                    <h4>33 QR</h4>
                    
                  </div>
                </div>
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-64.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                    
                        Geneve Mojito
                      
                    </h3>
                    
                  </div>
                  <div class="food-price">
                    <h4>33 QR</h4>
                   
                  </div>
                </div>
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-65.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                    
                        Mango Mesh
                      
                    </h3>
                   
                  </div>
                  <div class="food-price">
                    <h4>35 QR</h4>
                  
                  </div>
                </div>
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-66.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                     
                        Mango Mojito
                     
                    </h3>
                   
                  </div>
                  <div class="food-price">
                    <h4>25 QR</h4>
                   
                  </div>
                </div>
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-67.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                     
                        Passion Fruit Mojito
                      
                    </h3>
                    
                  </div>
                  <div class="food-price">
                    <h4>30 QR</h4>
                  
                  </div>
                </div>
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-68.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                      
                        Raspberry Mojito
                      
                    </h3>
                   
                  </div>
                  <div class="food-price">
                    <h4>28 QR</h4>
                  
                  </div>
                </div>
              </div>






              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-68A.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                      
                        Blue Lagoon Mojito
                     
                    </h3>
                   
                  </div>
                  <div class="food-price">
                    <h4>20 QR</h4>
                  
                  </div>
                </div>
              </div>

              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-68B.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                    
                        Kiwi Mojito
                     
                    </h3>
                    
                  </div>
                  <div class="food-price">
                    <h4>QR</h4>
                  
                  </div>
                </div>
              </div>

              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-68C.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                     
                        Santorini
                     
                    </h3>
                    
                  </div>
                  <div class="food-price">
                    <h4>35 QR</h4>
                  
                  </div>
                </div>
              </div>

              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-68D.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                      
                        Strawberry Mojito
                     
                    </h3>
                   
                  </div>
                  <div class="food-price">
                    <h4>28 QR</h4>
                  
                  </div>
                </div>
              </div>

              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-68E.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                     
                        Watermelon Mojito
                      
                    </h3>
                    
                  </div>
                  <div class="food-price">
                    <h4>25 QR</h4>
                  
                  </div>
                </div>
              </div>

              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-68F.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                     
                        Blueberry Mojito
                    
                    </h3>
                   
                  </div>
                  <div class="food-price">
                    <h4>28 QR</h4>
                  
                  </div>
                </div>
              </div>

              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-68G.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                     
                        Carcade Berry
                      
                    </h3>
                    
                  </div>
                  <div class="food-price">
                    <h4>33 QR</h4>
                  
                  </div>
                </div>
              </div>


            </div>
            <div class="tab-pane fade" id="tab_5" role="tabpanel">
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-69.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                   
                        Hot chocolate
                       
                    </h3>
                    
                  </div>
                  <div class="food-price">
                    <h4>22 QR</h4>
                 
                  </div>
                </div>
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-70.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                     
                        Hot tea
                    
                    </h3>
                    
                  </div>
                  <div class="food-price">
                    <h4>16 QR</h4>
                  
                  </div>
                </div>
              </div>
             
             
              
             
            </div>
            <div class="tab-pane fade" id="tab_6" role="tabpanel">
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-75.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                     
                        Coca Cola
                      
                    </h3>
                   
                  </div>
                  <div class="food-price">
                    <h4>5 QR</h4>
                  
                  </div>
                </div>
              </div>
              <div class="food-card style-five">
                <div class="food-img">
                  <img src="../../assets/img/food/food-76.webp" alt="Image" />
                </div>
                <div class="food-info-wrap">
                  <div class="food-title">
                    <h3>
                     
                        Water
                      
                    </h3>
                    
                  </div>
                  <div class="food-price">
                    <h4>3 QR</h4>
                  
                  </div>
                </div>
              </div>
             
             
            
            </div>
           
            
          </div>
         
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MenuClassic",
};
</script>

<style lang="scss" scoped>
.menu-tablist {
  &.style-one {
    border: none;
    .nav-item {
      border: none;
      display: block;
      width: 100%;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
      .nav-link {
        border: none;
        border-radius: 5px;
        border-bottom: 3px solid rgba(251, 171, 15, 0.3);
        background: var(--optionalColor);
        color: var(--titleColor);
        font-size: 17px;
        font-weight: 400;
        line-height: 15px;
        padding: 21px 25px;
        display: block;
        width: 100%;
        text-align: left;
        transition: var(--transition);
        &:hover,
        &.active {
          color: #ffe5a5;
          font-weight: 500;
          border-radius: 5px;
          border-bottom: 3px solid #A4AE46;
          background: var(--secondaryColor);
        }
      }
    }
  }
}
.food-card {
  margin-bottom: 30px;
  &.style-five {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    z-index: 1;
    margin-bottom: 25px;
    &:before {
      position: absolute;
      background-color: var(--whiteColor);
      top: 50%;
      right: 0%;
      content: "";
      width: 90%;
      height: 75%;
      border-radius: 20px;
      z-index: -1;
      transform: translateY(-50%);
    }
    .food-img {
      width: 211px;
      height: 211px;
      border-radius: 50%;
      img {
        border-radius: 50%;
      }
    }
    .food-info-wrap {
      width: calc(100% - 211px);
      padding: 30px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .food-title {
        width: 75%;
        position: relative;
        &:after {
          position: absolute;
          top: 17px;
          right: -30px;
          width: 170px;
          height: 1px;
          background-color: var(--optionalColor);
        }
        h3 {
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 12px;
          span {
            font-size: 14px;
            color: var(--paraColor);
            font-weight: 400;
            margin-left: 10px;
          }
          a {
            color: var(--titleColor);
            &:hover {
              color: var(--secondaryColor);
            }
          }
        }
        p {
          font-size: 15px;
          margin-bottom: 0;
        }
      }
      .food-price {
        width: 25%;
        text-align: right;
        h4 {
          color: var(--secondaryColor);
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 52px;
          position: relative;
          top: 4px;
        }
        a {
          visibility: hidden;
          opacity: 0;
          transition: var(--transition);
        }
      }
    }
    &:hover {
      .food-info-wrap {
        .food-price {
          .link-one {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .food-card {
    &.style-five {
      &:before {
        top: auto;
        bottom: 0;
        width: 100%;
        left: 0;
        transform: translateY(0);
      }
      .food-img {
        display: block;
        margin: 0 auto 25px;
      }
      .food-info-wrap {
        width: 100%;
        padding: 0 20px 22px;
        .food-title {
          width: 100%;
          &:after {
            display: none;
          }
        }
        .food-price {
          width: 100%;
          text-align: left;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px;
          h4 {
            top: auto;
            margin-bottom: 0;
            font-size: 18px;
          }
          a {
            visibility: visible;
            opacity: 1;
            transition: var(--transition);
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .food-card {
    &.style-five {
      .food-info-wrap {
        .food-title {
          &:after {
            width: 50px;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .food-card {
    &.style-five {
      .food-info-wrap {
        .food-title {
          &:after {
            width: 50px;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1400px) {
  .food-card {
    &.style-five {
      .food-info-wrap {
        .food-title {
          &:after {
            width: 240px;
            right: -95px;
          }
          p {
            max-width: 80%;
          }
        }
      }
    }
  }
}
</style>