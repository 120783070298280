<template>
  <div
    class="modal fade searchModal"
    id="searchModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <form>
          <input
            type="text"
            class="form-control"
            placeholder="Search here...."
          />
          <button type="submit"><i class="ri-search-line"></i></button>
        </form>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <i class="ri-close-fill"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchModal",
};
</script>

<style lang="scss" scoped>
.searchModal {
  overflow-x: hidden;
  .modal-dialog {
    margin: 0;
    width: 100%;
    max-width: 100%;
    padding: 30px 15px;
    background-color: var(--whiteColor);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    .modal-content {
      border: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      border-radius: 0;
      max-width: 720px;
      background-color: transparent;
      margin-left: auto;
      margin-right: auto;
      form {
        width: calc(100% - 25px);
        position: relative;
        .form-control {
          height: 55px;
          border-radius: 0;
          box-shadow: unset;
          border-radius: 5px;
          color: var(--blackColor);
          font-size: 14px;
          border: 1px solid #eeeeee;
          background-color: var(--whiteColor);
          padding-left: 18px;
          padding-right: 18px;
          &::placeholder {
            transition: var(--transition);
            color: var(--paraColor);
          }
          &:focus {
            &::-moz-placeholder {
              color: transparent;
            }

            &:-ms-input-placeholder {
              color: transparent;
            }

            &::placeholder {
              color: transparent;
            }
          }
          &:-moz-placeholder {
            -moz-transition: var(--transition);
            transition: var(--transition);
            color: var(--paragraphColor);
          }
          &:-ms-input-placeholder {
            -ms-transition: var(--transition);
            transition: var(--transition);
            color: var(--paragraphColor);
          }
        }
        button {
          top: 5px;
          border: 0;
          padding: 0;
          right: 5px;
          width: 45px;
          height: 45px;
          font-size: 17px;
          border-radius: 5px;
          position: absolute;
          color: var(--whiteColor);
          transition: var(--transition);
          background-color: var(--secondaryColor);
          &:hover {
            background-color: var(--titleColor);
            color: var(--whiteColor);
          }
          i {
            left: 0;
            right: 0;
            top: 50%;
            line-height: 1;
            position: absolute;
            color: var(--whiteColor);
            transform: translateY(-50%);
          }
        }
      }
      .btn-close {
        top: 13px;
        padding: 0;
        z-index: 2;
        opacity: 1;
        right: 3px;
        text-align: right;
        cursor: pointer;
        box-shadow: unset;
        position: absolute;
        background-color: transparent;
        background-image: none;
        i {
          font-size: 25px;
        }
      }
    }
  }
}
</style>