<template>
  <div class="feature-wrap style-three ptb-100">
    <div class="container">
      <div class="row align-items-center">
        <div
          class="col-lg-6"
          data-aos="fade-in"
          data-aos-duration="1200"
          data-aos-delay="200"
        >
          <div class="feature-img">
            <img src="../../assets/img/feature/feature-3.webp" alt="Image" />
          </div>
        </div>
        <div
          class="col-lg-6"
          data-aos="fade-in"
          data-aos-duration="1200"
          data-aos-delay="400"
        >
          <div class="feature-content">
            <div class="content-title style-one">
              <h6 class="section-subtitle">
                A Symphony Of <span>Freshness</span> On <span>Your Plate</span>
              </h6>
              <h2>
                Savor the flavors of Qatar at thetinyleaves where every bite tells a story 
              </h2>
              <p>
                We take pride in crafting unforgettable dining experiences that tantalize the senses and leave a lasting impression. Our menu is a culinary journey through the diverse landscapes of Qatar, blending traditional Qatari recipes with contemporary flair.
              </p>
            </div>
            <ul class="feature-list list-style">
              <li>
                <i class="ri-check-line"></i>Pick your meal for a low price
              </li>
              <li><i class="ri-check-line"></i>We create cherished memories</li>
              <li>
                <i class="ri-check-line"></i>Inspired by the simplicity of
                traditional cuisine
              </li>
            </ul>
           
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ASymphonyOfFreshness",
};
</script>

<style lang="scss" scoped>
.feature-wrap {
  &.style-three {
    .feature-content {
      .feature-list {
        margin: 22px 0 29px;
        li {
          margin-bottom: 15px;
          color: var(--titleColor);
          font-size: 18px;
          position: relative;
          padding-left: 37px;
          i {
            position: absolute;
            top: 5px;
            left: 0;
            color: var(--secondaryColor);
            font-size: 25px;
            line-height: 0.8;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .feature-btn {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .btn-one {
          margin-right: 27px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .feature-wrap {
    &.style-three {
      .feature-content {
        .feature-list {
          margin: 16px 0 25px;
          li {
            margin-bottom: 10px;
            font-size: 14px;
            padding-left: 32px;
          }
        }
        .feature-btn {
          .btn-one {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  .feature-wrap {
    &.style-three {
      .feature-img {
        margin-bottom: 25px;
      }
    }
  }
}
@media only screen and (min-width: 1400px) {
  .feature-wrap {
    &.style-three {
      .container {
        max-width: 1330px;
      }
      .feature-img {
        width: calc(100% - 50px);
      }
      .feature-content {
        width: calc(100% - 66px);
        margin-left: auto;
        .content-title {
          p {
            padding-right: 10px;
          }
        }
      }
    }
  }
}
</style>