<template>
  <div class="promo-wrap ptb-100">
    <div class="container">
      <div class="row">
        <div class="col-xl-10 offset-xl-1">
          <h2 class="section-title style-four pb-100">
            Quick, Crave-Worthy, and Delicious! Grab & Go Goodness!
          </h2>
        </div>
      </div>
      <div class="promo-bg bg-f">
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuickCraveWorthyDelicious",
};
</script>

<style lang="scss" scoped>
.promo-wrap {
  position: relative;
  z-index: 1;
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 50%;
    background-color: #A4AE46;
    z-index: -1;
  }
}
.promo-bg {
  background-image: url(../../assets/img/promo-bg.webp);
  height: 567px;
  border-radius: 50px;
  position: relative;
  .circle-wrap {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    position: absolute;
    top: -85px;
    right: 15px;
    z-index: 2;
    overflow: hidden;
    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      content: "";
      width: 136px;
      height: 136px;
      background-color: #ffe5a5;
      border-radius: 50%;
      z-index: -1;
      transform: translate(-50%, -50%);
    }
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .circle-text {
      width: 280px;
      height: 280px;
      position: absolute;
      top: 50%;
      left: 50%;
      overflow: hidden;
      transform: translate(-50%, -50%);
      svg {
        width: 430px;
        height: 430px;
        position: absolute;
        top: -75px;
        left: -75px;
        overflow: hidden;
        text {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .promo-wrap {
    &:before {
      width: calc(100% - 10px);
    }
  }
  .promo-bg {
    height: 420px;
    border-radius: 20px;
    .circle-wrap {
      top: -65px;
      right: 0px;
      z-index: 2;
      &:before {
        width: 100px;
        height: 100px;
      }
      .circle-text {
        svg {
          width: 300px;
          height: 300px;
          top: -10px;
          left: -10px;
          text {
            font-size: 13px;
            letter-spacing: 0.5px;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1920px) {
  .promo-wrap {
    &:before {
      width: calc(100% - 100px);
    }
  }
}
</style>