<template>
  <MainNavbar class="style-one" />
  <MainBanner />
  <BeveragesAndFood />
  <BestSeller />
  <MouthwateringFood />
  <GraveFastFood />
  <ExperienceInRestaurantServices />
  <SetOneGet />
  <ReservationTable />
  <TagList />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MainNavbar from "../components/Layouts/MainNavbar.vue";
import MainBanner from "../components/HomeOne/MainBanner.vue";
import BeveragesAndFood from "../components/HomeOne/MenuItems.vue";
import GraveFastFood from "../components/HomeOne/GraveFastFood.vue";
import ExperienceInRestaurantServices from "../components/HomeOne/ExperienceInRestaurantServices.vue";
import SetOneGet from "../components/HomeOne/SetOneGet.vue";
import BestSeller from "../components/HomeOne/BestSeller.vue";
import ReservationTable from "../components/HomeOne/ReservationTable.vue";
import TagList from "../components/HomeOne/TagList.vue";

export default defineComponent({
  name: "HomeOnePage",
  components: {
    MainNavbar,
    MainBanner,
    BeveragesAndFood,
    BestSeller,
    GraveFastFood,
    ExperienceInRestaurantServices,
    SetOneGet,
    ReservationTable,
    TagList,
  },
});
</script>