

<script>
export default {
  name: "ReservationTable",
};
</script>

<style lang="scss" scoped>
.reservation-form {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  input {
    width: calc(100% - 186px);
    margin-right: 15px;
    border: none;
    border-bottom: 1px solid #ccae79;
    color: #ccae79;
    font-size: 15px;
    font-weight: 400;
    line-height: 16px;
    height: 40px;
    letter-spacing: 0.3px;
    padding: 0 0 5px 0;
    &::placeholder {
      color: #ccae79;
    }
  }
  button {
    width: 171px;
    background-color: var(--secondaryColor);
    color: var(--optionalColor);
    border-radius: 50px;
    border: none;
    padding: 17.5px 5px 17.5px;
    color: #ffe5a5;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.32px;
    transition: var(--transition);
    &:hover {
      background-color: var(--titleColor);
      color: var(--whiteColor);
    }
  }
  &.style-two {
    input {
      border-bottom: 1px solid rgba(249, 244, 229, 0.22);
      color: #ffe5a5;
      &::placeholder {
        color: #ffe5a5;
        opacity: 1;
      }
    }
    button {
      background-color: var(--titleColor);
      color: var(--whiteColor);
      color: #ffe5a5;
      &:hover {
        background-color: var(--secondaryColor);
        color: var(--whiteColor);
      }
    }
  }
}

@media only screen and (min-width: 1400px) {
  .reservation-form {
    width: calc(100% - 65px);
    margin-left: auto;
  }
}
</style>