import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainNavbar = _resolveComponent("MainNavbar")!
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_ContactUs = _resolveComponent("ContactUs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MainNavbar, { class: "style-three" }),
    _createVNode(_component_PageTitle, {
      pageTitle: "Contact Us",
      mainTitle: "Contact Us",
      subTitle: "A Symphony Of Senses Welcome To Our Table"
    }),
    _createVNode(_component_ContactUs)
  ], 64))
}