import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainNavbar = _resolveComponent("MainNavbar")!
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_ASymphonyOfFreshness = _resolveComponent("ASymphonyOfFreshness")!
  const _component_SimpleText = _resolveComponent("SimpleText")!
  const _component_ExperienceInRestaurantServices = _resolveComponent("ExperienceInRestaurantServices")!
  const _component_ReservationTable = _resolveComponent("ReservationTable")!
  const _component_InstantGratification = _resolveComponent("InstantGratification")!
  const _component_QuickCraveWorthyDelicious = _resolveComponent("QuickCraveWorthyDelicious")!
  const _component_ExploringTheIntersection = _resolveComponent("ExploringTheIntersection")!
  const _component_BecomePartner = _resolveComponent("BecomePartner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MainNavbar, { class: "style-three" }),
    _createVNode(_component_PageTitle, {
      pageTitle: "About Us",
      mainTitle: "About Us",
      subTitle: "Where Every Dish Tells A Unique Tale"
    }),
    _createVNode(_component_ASymphonyOfFreshness),
    _createVNode(_component_SimpleText),
    _createVNode(_component_ExperienceInRestaurantServices),
    _createVNode(_component_ReservationTable),
    _createVNode(_component_InstantGratification),
    _createVNode(_component_QuickCraveWorthyDelicious),
    _createVNode(_component_ExploringTheIntersection),
    _createVNode(_component_BecomePartner)
  ], 64))
}