<template>
  <MainNavbar class="style-three" />
  <PageTitle
    pageTitle="About Us"
    mainTitle="About Us"
    subTitle="Where Every Dish Tells A Unique Tale"
  />
  <ASymphonyOfFreshness />
  <SimpleText />
  <ExperienceInRestaurantServices />
  <ReservationTable />
  <InstantGratification />
  <QuickCraveWorthyDelicious />
  <ExploringTheIntersection />
  <BecomePartner />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MainNavbar from "../components/Layouts/MainNavbar.vue";
import PageTitle from "../components/Common/PageTitle.vue";
import ASymphonyOfFreshness from "../components/AboutUs/ASymphonyOfFreshness.vue";
import SimpleText from "../components/AboutUs/SimpleText.vue";
import ExperienceInRestaurantServices from "../components/AboutUs/ExperienceInRestaurantServices.vue";
import ReservationTable from "../components/AboutUs/ReservationTable.vue";
import InstantGratification from "../components/AboutUs/InstantGratification.vue";
import QuickCraveWorthyDelicious from "../components/AboutUs/QuickCraveWorthyDelicious.vue";
import ExploringTheIntersection from "../components/AboutUs/ExploringTheIntersection.vue";
import BecomePartner from "../components/AboutUs/BecomePartner.vue";

export default defineComponent({
  name: "AboutUsPage",
  components: {
    MainNavbar,
    PageTitle,
    ASymphonyOfFreshness,
    SimpleText,
    ExperienceInRestaurantServices,
    ReservationTable,
    InstantGratification,
    QuickCraveWorthyDelicious,
    ExploringTheIntersection,
    BecomePartner,
  },
});
</script>