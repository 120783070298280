<template>
  <div class="breadcrumb-wrap">
    <div class="container-fluid">
      <div class="breadcrumb-menu list-style">
        <li><router-link to="/">Home</router-link></li>
        <li>{{ pageTitle }}</li>
      </div>
    </div>
    <div class="container br-wrap">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-7 ps-xxl-3">
          <div class="br-content">
            <h2>{{ mainTitle }}</h2>
            <h6 class="section-subtitle">
              {{ subTitle }}
            </h6>
          </div>
        </div>
        <div class="col-lg-6 col-md-5">
          <div class="br-img">
            <img src="../../assets/img/breadcrumb/br-1.webp" alt="Image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PageTitle",
  props: ["pageTitle", "mainTitle", "subTitle"],
});
</script>