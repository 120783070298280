<template>
  <swiper
    :spaceBetween="25"
    :grabCursor="true"
    :loop="false"
    :autoplay="{
      delay: 4000,
      disableOnInteraction: false,
    }"
    :breakpoints="{
      0: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 1,
      },
      992: {
        slidesPerView: 2,
      },
    }"
    class="testimonial-slider-one"
  >
    <swiper-slide v-for="testimonial in testimonials" :key="testimonial.id">
      <div class="testimonial-card style-one">
        <div class="client-info-wrap">
          <div class="client-img">
            <img :src="testimonial.image" alt="Image" />
          </div>
          <div class="client-info">
            <h5>{{ testimonial.name }}</h5>
            <span>{{ testimonial.designation }}</span>
          </div>
          <span class="quote-icon">
            <img src="../../assets/img/icons/quote-icon.svg" alt="Image" />
          </span>
        </div>
        <p class="client-quote">
          {{ testimonial.description }}
        </p>
        <div class="ratings">
          <ul class="list-style">
            <li>
              <img src="../../assets/img/icons/star.svg" alt="Image" />
            </li>
            <li>
              <img src="../../assets/img/icons/star.svg" alt="Image" />
            </li>
            <li>
              <img src="../../assets/img/icons/star.svg" alt="Image" />
            </li>
            <li>
              <img src="../../assets/img/icons/star.svg" alt="Image" />
            </li>
            <li>
              <img src="../../assets/img/icons/star-2.svg" alt="Image" />
            </li>
          </ul>
          <span>Rated 4.0</span>
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script>
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";

export default {
  name: "OurCustomer",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      testimonials: [
        {
          id: 1,
          image: require("../../assets/img/clients/client-1.webp"),
          name: "Atma A",
          description:
            "“Really delicious and clean malfoof. Worth trying. Amazing customer service Keep it up guys we love your food”",
        },
        {
          id: 1,
          image: require("../../assets/img/clients/client-1.webp"),
          name: "Atma A",
          description:
            "“Really delicious and clean malfoof. Worth trying. Amazing customer service Keep it up guys we love your food”",
        },
        {
          id: 1,
          image: require("../../assets/img/clients/client-1.webp"),
          name: "Atma A",
          description:
            "“Really delicious and clean malfoof. Worth trying. Amazing customer service Keep it up guys we love your food”",
        },
        {
          id: 1,
          image: require("../../assets/img/clients/client-1.webp"),
          name: "Atma A",
          description:
            "“Really delicious and clean malfoof. Worth trying. Amazing customer service Keep it up guys we love your food”",
        },
      ],
    };
  },
  setup() {
    return {
      Autoplay,
    };
  },
};
</script>

<style lang="scss" scoped>
.testimonial-slider-one {
  .testimonial-card {
    margin-bottom: 0;
  }
}
.testimonial-card {
  background-color: var(--whiteColor);
  border-radius: 20px;
  padding: 25px;
  margin-bottom: 30px;
  .client-info-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .client-img {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      img {
        border-radius: 50%;
      }
    }
    .client-info {
      width: calc(100% - 116px);
      margin-left: 15px;
      h5 {
        color: var(--titleColor);
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 5px;
      }
      span {
        font-size: 15px;
        line-height: 28px;
      }
    }
    .quote-icon {
      width: 45px;
      display: inline-block;
      i {
        margin-right: auto;
        line-height: 0.8;
        font-size: 44px;
        color: var(--secondaryColor);
      }
      img {
        margin-right: auto;
        display: block;
      }
    }
  }
  .client-quote {
    color: var(--titleColor);
    font-size: 18px;
    line-height: 32px;
    margin: 22px 0 57px;
  }
  .ratings {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    ul {
      position: relative;
      top: -2px;
      li {
        display: inline-block;
        i {
          color: var(--secondaryColor);
        }
      }
    }
    span {
      margin-left: 18px;
    }
  }
  &.style-two {
    background-color: transparent;
    padding: 0;
    text-align: center;
    .client-quote {
      color: var(--titleColor);
      text-align: center;
      font-size: 64px;
      font-weight: 400;
      line-height: 72px;
      margin: 0 0 25px;
    }
    .client-info-wrap {
      display: inline-flex;
      justify-content: center;
      margin: 0 auto;
      .client-info {
        width: calc(100% - 71px);
        margin-left: 15px;
        text-align: left;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-card {
    padding: 22px;

    &.style-two {
      .client-quote {
        font-size: 24px;
        line-height: 36px;
      }
    }
    .client-info-wrap {
      .client-img {
        width: 50px;
        height: 50px;
      }
      .client-info {
        width: calc(100% - 95px);
        margin-left: 10px;
        h5 {
          margin: 0;
          font-size: 17px;
        }
        span {
          font-size: 14px;
          line-height: 18px;
        }
      }
      .quote-icon {
        width: 30px;
        margin-left: auto;
        i {
          font-size: 30px;
        }
      }
    }
    .client-quote {
      font-size: 14px;
      line-height: 24px;
      margin: 24px 0 20px;
    }
    .ratings {
      ul {
        position: relative;
        top: 0px;
        li {
          display: inline-block;
          font-size: 18px;
          img {
            max-width: 17px;
          }
        }
      }
      span {
        margin-left: 10px;
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-card {
    &.style-two {
      .client-quote {
        font-size: 32px;
        line-height: 42px;
      }
    }
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-card {
    &.style-two {
      .client-quote {
        font-size: 46px;
        line-height: 56px;
      }
    }
  }
}
@media only screen and (min-width: 1400px) {
  .testimonial-card {
    padding: 45px 37px 42px 40px;
    &.style-two {
      .client-quote {
        font-size: 76px;
        line-height: 96px;
      }
    }
    .quote-icon {
      margin-left: auto;
    }
  }
}
</style>