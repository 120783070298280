<template>
  <div class="contact-us-wrap ptb-100">
    <div class="container">
      <div class="row pb-70">
        <div class="col-md-6">
          <div class="contact-card">
            <span class="contact-icon">
              <i class="ri-map-pin-line"></i>
            </span>
            <h3>Lusail</h3>
            <p><span><i class="ri-time-line"></i>  12:00 PM - 12:00 AM</span></p>
            <p><i class="ri-phone-line"></i> 33083870</p>
            <a href="http://google.com/maps/place/The+Tiny+Leaves,+Lusail/@25.3924881,51.5266617,16z/data=!4m2!3m1!1s0x3e45c31537559a21:0x970284471dbbbfe8?hl=en-QA&gl=qa" target="_blank" class="locate-us-btn">
              Locate us
            </a>
          </div>
        </div>
        <div class="col-md-6">
          <div class="contact-card">
            <span class="contact-icon">
              <i class="ri-map-pin-line"></i>
            </span>
            <h3>Aspire</h3>
            <p><span><i class="ri-time-line"></i>  12:00 PM - 12:00 AM</span></p>
            <p><i class="ri-phone-line"></i> 66777285</p>
            <a href="http://goo.gl/maps/HRrShQFGYhEtvH8t8" target="_blank" class="locate-us-btn">
              Locate us
            </a>
          </div>
        </div>
      </div>
    </div>
   
    <div class="container">
      <div class="row">
        <div class="col-xxl-8 offset-xxl-2 col-xl-10 offset-xl-1">
          <form class="form-wrap" id="contactForm">
            <h3>Get In Touch</h3>
            <div class="form-group">
              <label for="name">Your Name</label>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Enter your name"
                required
              />
              <i class="ri-user-3-line"></i>
            </div>
            <div class="form-group">
              <label for="email">Email Address</label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Enter your email"
                required
                data-error="Please enter your email"
              />
              <i class="ri-mail-line"></i>
            </div>
            <div class="form-group">
              <label for="message">Your Messgae</label>
              <textarea
                name="messages"
                id="messages"
                cols="30"
                rows="10"
                placeholder="Your comment here"
                data-error="Please enter your message"
              ></textarea>
              <i class="ri-message-2-line"></i>
            </div>
           
            <button type="submit" class="btn-one">Send Message</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactUs",
};
</script>

<style lang="scss" scoped>

.locate-us-btn{
  display: block;
  color: #FFE5A5;
    background-color: #5A633D;
    text-align: center;
    padding: 10px 30px;
    border-radius: 42px;
}
.contact-card {
  background-color: var(--whiteColor);
  border-radius: 20px;
  padding: 40px 40px;
  margin-bottom: 30px;
  text-align: center;
  .contact-icon {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    width: 74px;
    height: 74px;
    border-radius: 50%;
    margin: 0 auto 20px;
    background-color: var(--optionalColor);
    i {
      font-size: 32px;
      line-height: 0.8;
      display: block;
      margin: 0 auto;
      color: var(--secondaryColor);
    }
  }
  h3 {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 14px;
  }
  p {
    margin-bottom: 10px;
    padding: 0 10px;
  }
  a {
    position: relative;
    display: inline-block;
    i {
      position: absolute;
      top: 0px;
      left: 0;
      font-size: 24px;
      font-weight: 300;
      color: var(--secondaryColor);
    }
  }
}
.comp-map {
  width: 100%;
  height: 673px;
  iframe {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .contact-card {
    padding: 25px 20px;
    p {
      padding: 0;
    }
    a {
      font-size: 14px;
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-card {
    padding: 30px 20px;
    p {
      padding: 0;
    }
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-card {
    p {
      padding: 0 20px;
    }
  }
}
@media only screen and (min-width: 1400px) {
  .contact-card {
    p {
      padding: 0 100px;
    }
  }
}
</style>