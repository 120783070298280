import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainNavbar = _resolveComponent("MainNavbar")!
  const _component_MainBanner = _resolveComponent("MainBanner")!
  const _component_BeveragesAndFood = _resolveComponent("BeveragesAndFood")!
  const _component_BestSeller = _resolveComponent("BestSeller")!
  const _component_MouthwateringFood = _resolveComponent("MouthwateringFood")!
  const _component_GraveFastFood = _resolveComponent("GraveFastFood")!
  const _component_ExperienceInRestaurantServices = _resolveComponent("ExperienceInRestaurantServices")!
  const _component_SetOneGet = _resolveComponent("SetOneGet")!
  const _component_ReservationTable = _resolveComponent("ReservationTable")!
  const _component_TagList = _resolveComponent("TagList")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MainNavbar, { class: "style-one" }),
    _createVNode(_component_MainBanner),
    _createVNode(_component_BeveragesAndFood),
    _createVNode(_component_BestSeller),
    _createVNode(_component_MouthwateringFood),
    _createVNode(_component_GraveFastFood),
    _createVNode(_component_ExperienceInRestaurantServices),
    _createVNode(_component_SetOneGet),
    _createVNode(_component_ReservationTable),
    _createVNode(_component_TagList)
  ], 64))
}