<template>
  <footer class="footer-wrap pt-100">
    <div class="container">

      <div class="row pt-100 pb-70">
        <div class="col-xl-4 col-lg-6">
          <div class="footer-widget">
            <router-link to="/" class="footer-logo">
              <img src="../../assets/img/logo-white.png" alt="Image" />
            </router-link>
            <p class="comp-desc">
              We take pride in crafting unforgettable dining experiences that tantalize the senses and leave a lasting impression. Our menu is a culinary journey through the diverse landscapes of Qatar, blending traditional Qatari recipes with contemporary flair.
            </p>
          </div>
        </div>
        <div class="col-xl-3 col-lg-6">
          <div class="footer-widget">
            <h3 class="footer-widget-title">Restaurant</h3>
            <ul class="footer-menu list-style">
              <li><router-link to="/about">About us</router-link></li>
              <li><router-link to="/menu-classic">Menu</router-link></li>
              <li><router-link to="/contact">Contact us</router-link></li>
            </ul>
          </div>
        </div>
        <div class="col-xl-3 col-lg-6 pe-xxl-0">
          <div class="footer-widget">
            <h3 class="footer-widget-title">thetinyleaves</h3>
            <div class="address-item-wrap">
              <div class="address-item">
                <p>Lusail <br>
                  <span><i class="ri-time-line"></i>  12:00 PM - 12:00 AM</span> <br>
                  <span><i class="ri-phone-line"></i> 33083870</span>
                </p>

                <p>Aspire<br>
                  <span><i class="ri-time-line"></i>  12:00 PM - 12:00 AM</span> <br>
                  <span><i class="ri-phone-line"></i> 66777285</span>
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>
      <p class="copyright-text">
        <i class="ri-copyright-line"></i><span>thetinyleaves</span> is designed by
        <a href="https://barzantech.com/" target="_blank" >Barzantech</a>
      </p>
    </div>
  </footer>
</template>



<style lang="scss" scoped>
.footer-wrap {
  background-color: #131313;
  .footer-widget {
    margin-bottom: 30px;
    .footer-widget-title {
      color: var(--optionalColor);
      font-size: 18px;
      font-weight: 500;
      line-height: 16px;
      margin-bottom: 23px;
    }
    .footer-logo {
      display: block;
      margin-bottom: 28px;
    }
    .footer-menu {
      li {
        margin-bottom: 14px;
        a {
          color: var(--paraColor);
          line-height: 16px;

          &:hover {
            color: var(--whiteColor);
          }
        }
      }
    }
    .address-item-wrap {
      margin-top: 30px;
      .address-item {
        margin-bottom: 18px;
        &:last-child {
          margin-bottom: 0;
        }
        h6 {
          color: var(--secondaryColor);
          font-size: 16px;
          font-weight: 400;
          line-height: 16px;
          margin-bottom: 14px;
        }
      }
    }
  }
  .copyright-text {
    border-top: 1px solid rgba(255, 255, 255, 0.06);
    text-align: center;
    padding: 35px 0;
    color: #718a96;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: 0.32px;
    margin: 0;
    span,
    a {
      color: var(--secondaryColor);
      font-weight: 600;
    }
    i {
      position: relative;
      margin-right: 1px;
      top: -1px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .footer-wrap {
    .footer-widget {
      .footer-widget-title {
        margin-bottom: 18px;
      }
      .footer-logo {
        margin-bottom: 15px;
      }
      .footer-menu {
        li {
          margin-bottom: 6px;
          a {
            font-size: 14px;
          }
        }
      }
      .address-item-wrap {
        margin-top: 23px;
        .address-item {
          h6 {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1200px) {
  .footer-wrap {
    .footer-widget {
      .footer-widget-title {
        margin-top: 8px;
      }
    }
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .footer-wrap {
    .row {
      & > div {
        &:nth-child(2) {
          padding-left: 55px;
        }
      }
    }
  }
}
@media only screen and (min-width: 1400px) {
  .footer-wrap {
    .row {
      & > div {
        &:nth-child(2) {
          padding-left: 85px;
        }
        &:nth-child(3) {
          margin-left: -10px;
        }
        &:nth-child(4) {
          padding-left: 55px;
        }
      }
    }
  }
}
</style>