

<script>
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";

export default {
  name: "ExploringTheIntersection",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      reviews: [
        {
          id: 1,
          image: require("../../assets/img/clients/client-1.webp"),
          name: "Morgan Regan",
          designation: "Digital Marketer, xETC",
          description:
            "“What an outstanding dinner you prepared. It was an honor for me to be invited and experience firsthand.”",
        },
        {
          id: 2,
          image: require("../../assets/img/clients/client-2.webp"),
          name: "Jenifar Monika",
          designation: "Digital Marketer, xETC",
          description:
            "“What an outstanding dinner you prepared. It was an honor for me to be invited and experience firsthand.”",
        },
        {
          id: 3,
          image: require("../../assets/img/clients/client-3.webp"),
          name: "Aurora Silverwood",
          designation: "Digital Marketer, xETC",
          description:
            "“What an outstanding dinner you prepared. It was an honor for me to be invited and experience firsthand.”",
        },
        {
          id: 4,
          image: require("../../assets/img/clients/client-4.webp"),
          name: "Caleb Stormrider",
          designation: "Digital Marketer, xETC",
          description:
            "“What an outstanding dinner you prepared. It was an honor for me to be invited and experience firsthand.”",
        },
        {
          id: 5,
          image: require("../../assets/img/clients/client-2.webp"),
          name: "Asher Nightshade",
          designation: "Digital Marketer, xETC",
          description:
            "“What an outstanding dinner you prepared. It was an honor for me to be invited and experience firsthand.”",
        },
      ],
    };
  },
  setup() {
    return {
      Autoplay,
      modules: [Pagination],
    };
  },
};
</script>

<style lang="scss" scoped>
.testimonial-card {
  background-color: var(--whiteColor);
  border-radius: 20px;
  padding: 25px;
  margin-bottom: 30px;
  .client-info-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .client-img {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      img {
        border-radius: 50%;
      }
    }
    .client-info {
      width: calc(100% - 116px);
      margin-left: 15px;
      h5 {
        color: var(--titleColor);
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 5px;
      }
      span {
        font-size: 15px;
        line-height: 28px;
      }
    }
    .quote-icon {
      width: 45px;
      display: inline-block;
      i {
        margin-right: auto;
        line-height: 0.8;
        font-size: 44px;
        color: var(--secondaryColor);
      }
      img {
        margin-right: auto;
        display: block;
      }
    }
  }
  .client-quote {
    color: var(--titleColor);
    font-size: 18px;
    line-height: 32px;
    margin: 22px 0 57px;
  }
  .ratings {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    ul {
      position: relative;
      top: -2px;
      li {
        display: inline-block;
        i {
          color: var(--secondaryColor);
        }
      }
    }
    span {
      margin-left: 18px;
    }
  }
  &.style-two {
    background-color: transparent;
    padding: 0;
    text-align: center;
    .client-quote {
      color: var(--titleColor);
      text-align: center;
      font-size: 64px;
      font-weight: 400;
      line-height: 72px;
      margin: 0 0 25px;
    }
    .client-info-wrap {
      display: inline-flex;
      justify-content: center;
      margin: 0 auto;
      .client-info {
        width: calc(100% - 71px);
        margin-left: 15px;
        text-align: left;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-card {
    padding: 22px;
    .client-info-wrap {
      .client-img {
        width: 50px;
        height: 50px;
      }
      .client-info {
        width: calc(100% - 95px);
        margin-left: 10px;
        h5 {
          margin: 0;
          font-size: 17px;
        }
        span {
          font-size: 14px;
          line-height: 18px;
        }
      }
      .quote-icon {
        width: 30px;
        margin-left: auto;
        i {
          font-size: 30px;
        }
      }
    }
    .client-quote {
      font-size: 14px;
      line-height: 24px;
      margin: 24px 0 20px;
    }
    .ratings {
      ul {
        position: relative;
        top: 0px;
        li {
          display: inline-block;
          font-size: 18px;
          img {
            max-width: 17px;
          }
        }
      }
      span {
        margin-left: 10px;
      }
    }
  }
}
@media only screen and (min-width: 1400px) {
  .testimonial-card {
    padding: 45px 37px 42px 40px;

    .quote-icon {
      margin-left: auto;
    }
  }
}
</style>