<template>
  <div class="feature-wrap style-two ptb-100">
    <div class="container">
      <div class="row align-items-center">
        <div
          class="col-lg-6"
          data-aos="fade-in"
          data-aos-duration="1200"
          data-aos-delay="200"
        >
          <div class="feature-content">
            <div class="content-title style-one">
              <h6 class="section-subtitle">
                Instant <span>Gratification</span>, Tasty
                <span>Sensation</span>!
              </h6>
              <h2>Discover the perfect blend of tradition and innovation in every dish</h2>
              <p>
                Indulge in the rich aromas and vibrant spices of our dishes, each thoughtfully prepared using the finest locally-sourced ingredients. Step into our elegant yet welcoming ambiance, where modern design elements seamlessly blend with traditional Qatari influences. 
              </p>
            </div>
            <ul class="feature-list list-style">
              <li>Deliciously Swift, Always a Lift!</li>
              <li>Dine in service & Takeaway</li>
            </ul>
          </div>
        </div>
        <div
          class="col-lg-6 pe-xxl-0"
          data-aos="fade-in"
          data-aos-duration="1200"
          data-aos-delay="400"
        >
          <div class="feature-img-wrap">
            <div class="feature-img">
              <img src="../../assets/img/feature/feature-2.webp" alt="Image" />
            </div>
            <div class="counter-card-wrap">
              <div class="counter-card">
                <div class="counter-num">
                  <h4 class="counter">2</h4>
                </div>
                <p><b>Outlines</b> in Qatar</p>
              </div>
              <div class="counter-card">
                <div class="counter-num">
                  <h4 class="counter">120</h4>
                  <span>+</span>
                </div>
                <p><b>Foods</b> available in our menu.</p>
              </div>
              <div class="counter-card">
                <div class="counter-num">
                  <h4 class="counter">2</h4>
                  <span>m</span>
                </div>
                <p><b>Order</b> completed over the time.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InstantGratification",
};
</script>

<style lang="scss" scoped>
.feature-wrap {
  &.style-two {
    .feature-content {
      .content-title {
        h2 {
          font-weight: 700;
        }
      }
      .feature-list {
        margin: 28px 0 0;
        li {
          background-color: var(--whiteColor);
          color: var(--titleColor);
          font-size: 18px;
          font-weight: 500;
          position: relative;
          padding: 19px 20px 19px 62px;
          margin-bottom: 15px;
          border-radius: 10px;
          &:last-child {
            margin-bottom: 0;
          }
          &:before {
            position: absolute;
            top: 26px;
            left: 30px;
            content: "";
            width: 12px;
            height: 12px;
            background-color: var(--secondaryColor);
          }
        }
      }
    }
    .feature-img-wrap {
      position: relative;
      .feature-img {
        border-radius: 20px;
        display: block;
        margin-left: auto;
        img {
          display: block;
          margin-left: auto;
          border-radius: 20px;
        }
      }
      .counter-card-wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        background-color: var(--medical_titleColor);
        border-radius: 5px;
        position: absolute;
        bottom: 35px;
        left: 0;
        max-width: 535px;
        z-index: 1;
        padding: 30px 35px 20px 35px;
        border-radius: 30px 0px 30px 30px;
        background: var(--optionalColor);
        &:before {
          position: absolute;
          top: -5px;
          left: -5px;
          width: calc(100% + 10px);
          height: calc(100% + 10px);
          content: "";
          border: 1px solid var(--optionalColor);
          border-radius: 30px 0px 30px 30px;
        }
        .counter-card {
          width: 33.33%;
          position: relative;
          margin-bottom: 15px;
          .counter-num {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .counter,
            span {
              font-size: 40px;
              line-height: 40px;
              font-weight: 500;
              margin: 0;
              letter-spacing: -1.6px;
              background: radial-gradient(
                85% 85% at 50.38% 35%,
                #A4AE46 40.33%,
                #A4AE46 84.02%,
                #A4AE46 100%
              );
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              position: relative;
            }
          }
          p {
            font-size: 14px;
            line-height: 20px;
            margin: 7px 0 0;
            b {
              color: var(--titleColor);
              font-weight: 700;
            }
          }
          &:nth-child(1) {
            padding-right: 25px;
          }
          &:nth-child(2) {
            padding-left: 10px;
            padding-right: 25px;
          }
          &:nth-child(3) {
            padding-left: 18px;
            padding-right: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .feature-wrap {
    &.style-two {
      .feature-content {
        .feature-list {
          margin: 16px 0 25px;
          li {
            margin-bottom: 10px;
            font-size: 14px;
            padding-left: 32px;
          }
        }
      }
      .feature-img-wrap {
        padding-bottom: 60px;
        .feature-img {
          max-width: 92%;
        }
        .counter-card-wrap {
          max-width: 100%;
          padding: 23px 25px 0px 25px;
          justify-content: center;
          bottom: 0;
          .counter-card {
            width: 100%;
            position: relative;
            margin-bottom: 25px;
            text-align: center;
            .counter-num {
              justify-content: center;
              .counter,
              span {
                font-size: 30px;
                line-height: 30px;
              }
            }
            p {
              font-size: 14px;
              line-height: 20px;
              margin: 7px 0 0;
              b {
                color: var(--titleColor);
                font-weight: 700;
              }
            }
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
              padding-right: 0;
              padding-left: 0;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  .feature-wrap {
    &.style-two {
      .feature-content {
        margin-bottom: 32px;
      }
    }
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature-wrap {
    &.style-two {
      &.style-two {
        .feature-img-wrap {
          .feature-img {
            max-width: 88%;
          }
          .counter-card-wrap {
            max-width: 450px;
            padding: 30px 20px 20px 20px;
            border-radius: 30px 0px 30px 30px;
            .counter-card {
              &:nth-child(1) {
                padding-right: 15px;
              }
              &:nth-child(2) {
                padding-left: 10px;
                padding-right: 15px;
              }
              &:nth-child(3) {
                padding-left: 12px;
                padding-right: 0;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1400px) {
  .feature-wrap {
    &.style-two {
      .container {
        max-width: 1330px;
      }
      .feature-content {
        width: calc(100% - 50px);
        .content-title {
          p {
            padding-right: 20px;
          }
        }
        .feature-list {
          width: calc(100% - 55px);
        }
      }
      .feature-img-wrap {
        .feature-img {
          width: calc(100% - 103px);
        }
        .counter-card-wrap {
          left: -21px;
        }
      }
    }
  }
}
</style>