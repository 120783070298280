<template>
  <PreLoader />
  <ResponsiveNavbar />
  <SearchModal />
  <router-view />
  <LightDarkSwtichBtn />
  <BackToTop />
  <MainFooter v-if="shouldHideFooter" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRoute } from "vue-router";

import PreLoader from "./components/Layouts/PreLoader.vue";
import ResponsiveNavbar from "./components/Layouts/ResponsiveNavbar.vue";
import SearchModal from "./components/Layouts/SearchModal.vue";
import LightDarkSwtichBtn from "./components/Layouts/LightDarkSwtichBtn.vue";
import BackToTop from "./components/Layouts/BackToTop.vue";
import MainFooter from "./components/Layouts/MainFooter.vue";

export default defineComponent({
  name: "App",
  components: {
    PreLoader,
    ResponsiveNavbar,
    SearchModal,
    LightDarkSwtichBtn,
    BackToTop,
    MainFooter,
  },
  computed: {
    shouldHideFooter() {
      const route = useRoute();
      const hiddenRoutes = ["/:pathMatch(.*)*"];
      return !hiddenRoutes.includes(route.path);
    },
  },
});
</script>