

<script>
export default {
  name: "ExperienceInRestaurantServices",
};
</script>

<style lang="scss" scoped>
.combo-card {
  margin-bottom: 30px;
  min-height: 338px;
  &.style-one {
    background-color: #1e2e53;
    padding: 47px 35px 40px 45px;
    border-radius: 30px;
    h5 {
      color: var(--whiteColor);
      font-size: 20px;
      font-weight: 400;
      letter-spacing: 0.2px;
    }
    .service-amt {
      position: relative;
      padding: 55px 0 62px 85px;
      img {
        position: absolute;
        bottom: 2px;
        left: -15px;
      }
      h4 {
        color: var(--optionalColor);
        font-size: 50px;
        font-weight: 700;
        margin-bottom: 0;
      }
      p {
        font-size: 14px;
        line-height: 22px;
        font-weight: 200;
        letter-spacing: 0.28px;
        margin-bottom: 0;
        color: var(--optionalColor);
      }
    }
  }
  &.style-two {
    background-color: var(--whiteColor);
    border-radius: 30px;
    padding: 31px 35px 35px;
    .combo-title {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 75px;
      h3 {
        font-size: 20px;
        font-weight: 500;
        position: relative;
        width: calc(100% - 140px);
        margin-right: 15px;
        margin-bottom: 0;
        &:after {
          position: absolute;
          bottom: -32px;
          left: 0;
          content: "";
          width: 100%;
          height: 10px;
          background: radial-gradient(
            54.36% 59.6% at 44.84% 96.85%,
            #ffb4b4 0%,
            rgba(251, 171, 15, 0.24) 73.48%,
            rgba(244, 126, 34, 0) 100%
          );
        }
        a {
          color: var(--titleColor);
          &:hover {
            color: var(--secondaryColor);
          }
        }
      }
      .combo-img {
        width: 124px;
        height: 124px;
        border-radius: 50%;
        img {
          border-radius: 50%;
        }
      }
    }
    .combo-amt {
      h2 {
        font-size: 40px;
        font-weight: 500;
        letter-spacing: -1.6px;
        background: radial-gradient(
          85% 85% at 50.38% 35%,
          #ed3d33 40.33%,
          #ff8049 84.02%,
          #ffe5a5 100%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 0;
      }
      p {
        margin-bottom: 0;
        font-size: 14px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .combo-card {
    &.style-one {
      padding: 37px 25px 45px 25px;
    }
    &.style-two {
      padding: 31px 25px 35px;
    }
  }
}
</style>