<template>
  <div :class="['navbar-area', { sticky: isSticky }]">
    <div class="container">
      <nav class="navbar navbar-expand-lg">
        <router-link class="navbar-brand d-lg-none" to="/">
          <img class="logo-light" src="../../assets/img/logo.png" alt="logo" />
          <img
            class="logo-dark"
            src="../../assets/img/logo-white.png"
            alt="logo"
          />
        </router-link>
        <div class="other-option d-lg-none">
          <div class="option-item">
            <button
              type="button"
              class="search-btn"
              data-bs-toggle="modal"
              data-bs-target="#searchModal"
            >
              <i class="ri-search-line"></i>
            </button>
          </div>
          <a
            class="navbar-toggler"
            data-bs-toggle="offcanvas"
            href="#navbarOffcanvas"
            role="button"
            aria-controls="navbarOffcanvas"
          >
            <span class="burger-menu">
              <span class="top-bar"></span>
              <span class="middle-bar"></span>
              <span class="bottom-bar"></span>
            </span>
          </a>
        </div>
        <div class="collapse navbar-collapse">
          <a
            class="sidebar-toggler"
            data-bs-toggle="offcanvas"
            href="#navbarOffcanvas"
            role="button"
            aria-controls="navbarOffcanvas"
          >
            <img src="../../assets/img/icons/sidebar-menu.svg" alt="Image" />
          </a>
          <router-link class="navbar-brand mx-auto" to="/">
            <img
              class="logo-light"
              src="../../assets/img/logo.png"
              alt="logo"
            />
            <img
              class="logo-dark"
              src="../../assets/img/logo-white.png"
              alt="logo"
            />
          </router-link>  
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link to="/" class="nav-link"> Home </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/about" class="nav-link"> About Us </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/menu-classic" class="nav-link"> Menu </router-link>
            </li>
             <li class="nav-item">
              <router-link to="/contact" class="nav-link"> Contact </router-link>
            </li>
          </ul>
          
          <div class="others-option d-flex align-items-center">
            
            <div class="option-item">
            
               <a href="http://thetinyleaves.com/thetinyleaves-menu.pdf" target="_blank"  class="btn-one">Download Menu</a>

            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";

export default defineComponent({
  name: "MainNavbar",
  setup() {
    const isSticky = ref(false);

    onMounted(() => {
      window.addEventListener("scroll", () => {
        let scrollPos = window.scrollY;
        isSticky.value = scrollPos >= 100;
      });
    });

    return {
      isSticky,
    };
  },
});
</script>

<style lang="scss" scoped>
.navbar-area {
  z-index: 10;
  &.sticky {
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    position: fixed;
    background-color: var(--primaryColor);
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
    .navbar {
      border-radius: 0;
      .navbar-nav {
        .nav-item {
          .nav-link {
            padding-top: 30px;
            padding-bottom: 30px;
          }
          .dropdown-toggle {
            &::before {
              top: 36px;
            }
          }
        }
        & > .nav-item {
          & > .nav-link,
          & > .dropdown-toggle {
            &:after {
              bottom: 35.5px;
            }
          }
        }
      }
      .others-option {
        top: 1px;
      }
    }
    &.style-one,
    &.style-two {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9;
    }
    &.style-one,
    &.style-two,
    &.style-three {
      .navbar-toggler {
        top: 15px;
      }
    }
    &.style-one {
      background-color: #FFF;
      padding: 15px 0;
    }
    &.style-two {
      background-color: var(--optionalColor);
    }
  }
  .navbar {
    padding: 0;
    .navbar-brand {
      padding: 0;
      margin-left: 55px !important;
        margin-right: 0 !important;	
    }
    .navbar-toggler {
      color: var(--titleColor);
      font-size: inherit;
      box-shadow: unset;
      border: none;
      padding: 0;
      position: absolute;
      top: 19px;
      right: 0;
      .burger-menu {
        cursor: pointer;
        span {
          height: 3px;
          width: 30px;
          margin: 5px 0;
          display: block;
          background: var(--titleColor);
        }
      }
    }
    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;
    }
    .navbar-nav {
      margin-left: 39px;
      .nav-item {
        position: relative;
        .nav-link {
          position: relative;
          transition: var(--transition);
          font-weight: 400;
          padding-left: 0;
          padding-top: 46px;
          padding-bottom: 47px;
        }
        &:hover {
          .dropdown-menu {
            opacity: 1;
            visibility: visible;
            transform: translate3d(0, 0, 0);
          }
        }
        .dropdown-menu {
          left: 0;
          top: 80px;
          opacity: 0;
          z-index: 99;
          width: 295px;
          margin-top: 0;
          display: block;
          padding: 12px 30px;
          border-radius: 5px;
          position: absolute;
          visibility: hidden;
          background: var(--whiteColor);
          transition: all 0.2s ease-in-out;
          border: none;
          transform: translate3d(0, 18px, 0);
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
          .nav-item {
            margin: 0;
            .nav-link {
              display: block;
              font-size: 15px;
              padding: 8px 0;
              color: var(--titleColor);
              position: relative;
              border-bottom: 1px solid rgba(0, 86, 67, 0.1);
              transition: var(--transition);
              &.dropdown-toggle {
                &:after {
                  display: inline-block;
                  border: none;
                  margin: 0;
                }
              }
              &:after {
                position: absolute;
                top: 50%;
                left: -30px;
                content: "";
                width: 3px;
                height: 0;
                background-color: var(--secondaryColor);
                transform: translateY(-50%);
                visibility: hidden;
                opacity: 0;
                transition: var(--transition);
              }
              &.active,
              &:hover {
                color: var(--secondaryColor);
                border-bottom-color: var(--secondaryColor);
                &:after {
                  visibility: visible;
                  opacity: 1;
                  height: 25px;
                }
              }
              &.dropdown-toggle {
                &:before {
                  top: 50%;
                  right: 0;
                  transform: translateY(-50%) rotate(-90deg);
                }
              }
            }
            &:last-child {
              .nav-link {
                border-bottom: none;
              }
            }
            .dropdown-menu {
              top: 0;
              opacity: 0;
              left: 235px;
              margin-top: 0;
              visibility: hidden;
              transform: translate3d(0, 18px, 0);
              .nav-item {
                .nav-link {
                  color: var(--titleColor);
                  &.active,
                  &:hover {
                    color: var(--secondaryColor);
                  }
                  &:hover {
                    .dropdown-menu {
                      opacity: 1;
                      visibility: visible;
                      transform: translate3d(0, 0, 0);
                    }
                  }
                }
                .dropdown-menu {
                  top: 0;
                  opacity: 0;
                  left: -100%;
                  visibility: hidden;
                  transform: translate3d(0, 18px, 0);
                  .nav-item {
                    &:hover {
                      .dropdown-menu {
                        opacity: 1;
                        visibility: visible;
                        transform: translate3d(0, 0, 0);
                      }
                    }
                    .dropdown-menu {
                      top: 0;
                      opacity: 0;
                      left: 100%;
                      visibility: hidden;
                      transform: translate3d(0, 18px, 0);
                      .nav-item {
                        .nav-link {
                          color: var(--titleColor);
                          &:hover,
                          &.active {
                            color: var(--secondaryColor);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            &:hover {
              .dropdown-menu {
                opacity: 1;
                visibility: visible;
                transform: translate3d(0, 0, 0);
              }
            }
          }
        }
        .dropdown-toggle {
          &:before {
            position: absolute;
            content: "\ea4d";
            line-height: 1;
            right: -1.5px;
            top: 51.5px;
            font-family: remixicon !important;
            font-size: 16px;
            font-weight: 400;
          }
          &:after {
            display: none;
          }
        }
      }
      & > .nav-item {
        & > .nav-link {
          padding-right: 20px;
        }
        & > .nav-link,
        & > .dropdown-toggle {
          position: relative;
          &:after {
            display: inline-block;
            position: absolute;
            bottom: 53.5px;
            left: 0;
            content: "";
            height: 1px;
            visibility: hidden;
            opacity: 0;
            border: none;
            margin: 0;
            transition: var(--transition);
          }
          &:hover,
          &.active {
            &:after {
              visibility: visible;
              opacity: 1;
            }
          }
        }
        & > .nav-link {
          &:after {
            width: calc(100% - 11px);
          }
          &.dropdown-toggle {
            &:after {
              width: calc(100% - 27px);
            }
          }
        }
      }
    }
    .others-option {
      position: relative;
      margin-left: 20px;
      top: 3px;
      .option-item {
        .btn-one {
          position: relative;
          top: 2px;
        }
      }
    }
  }
  &.style-one,
  &.style-two {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: transparent;
  }
  &.style-one {
    .navbar {
      .navbar-nav {
        .nav-item {
          .nav-link {
            color: var(--optionalColor);
            &.active,
            &:hover {
              color: #A4AE46;
              &:before {
                color: #A4AE46;
              }
            }
          }
        }
        & > .nav-item {
          & > .nav-link,
          & > .dropdown-toggle {
            &:after {
              background-color: #A4AE46;
            }
          }
        }
      }
      .others-option {
        .option-item {
          .search-form {
            input {
              border-bottom-color: rgba(255, 255, 255, 0.25);
            }
            button {
              i {
                color: var(--optionalColor);
              }
            }
          }
        }
      }
      .navbar-toggler {
        .burger-menu {
          span {
            background: var(--whiteColor);
          }
        }
      }
    }
    .navbar-brand {
      .logo-dark {
        display: block !important;
      }
      .logo-light {
        display: none !important;
      }
    }
  }
  &.style-two,
  &.style-three {
    .navbar {
      .navbar-nav {
        .nav-item {
          .nav-link {
            color: var(--titleColor);
            &.active,
            &:hover {
              color: var(--secondaryColor);
              &:before {
                color: var(--secondaryColor);
              }
            }
          }
        }
        & > .nav-item {
          & > .nav-link,
          & > .dropdown-toggle {
            &:after {
              background-color: var(--secondaryColor);
            }
          }
        }
      }
      .others-option {
        .option-item {
          .search-form {
            input {
              color: var(--titleColor);
              &::placeholder {
                color: var(--titleColor);
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}
.sidebar-toggler {
  position: relative;
  top: -0.9px;
}
.search-form {
  position: relative;
  width: 217px;
  input {
    width: 100%;
    height: 45px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #e4ddcf;
    color: #ccae79;
    font-size: 15px;
    line-height: 15px;
    letter-spacing: 0.3px;
    padding: 0;
    &::placeholder {
      color: #ccae79;
      opacity: 1;
    }
  }
  button {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: transparent;
    border: none;
    padding: 5px 0;
    i {
      color: var(--secondaryColor);
      font-size: 24px;
      line-height: 0.8;
    }
  }
}
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

//Responsive CSS
@media only screen and (max-width: 991px) {
  .navbar-area.sticky .navbar {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .navbar-area {
    &.sticky {
      padding: 0;
    }
    .navbar {
      padding: 15px 0;
      .navbar-brand {
        img {
          max-width: 120px;
        }
      }
      .other-option {
        display: flex;
        align-items: center;
        margin-right: 49px;
        .option-item {
          margin-right: 0;
          .search-btn {
            background-color: transparent;
            padding: 0;
            border: none;
            position: relative;
            top: 1px;
            i {
              font-size: 22px;
              font-weight: 300;
              color: var(--secondaryColor);
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navbar-area {
    .container {
      max-width: 980px;
    }
    .navbar-brand {
      max-width: 120px;
    }
    .navbar {
      .navbar-nav {
        margin-left: 25px;
        .nav-item {
          .nav-link {
            font-size: 15px;
          }
        }
        & > .nav-item {
          margin-right: 15px;
          & > .nav-link {
            padding-right: 7px;
            &.dropdown-toggle {
              padding-right: 18px;
              &:before {
                right: 2px;
              }
            }
          }
        }
      }
      .others-option {
        .option-item {
          margin-right: 15px;
          &:last-child {
            margin-right: 0;
          }
          .btn-one {
            padding: 10px 30px 9px;
            font-size: 15px;
          }
        }
      }
      .navbar-brand {
        position: relative;
        left: -15px;
      }
    }
    .search-form {
      width: 180px;
    }
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .navbar-area {
    .container {
      max-width: 1180px;
    }
    .navbar {
      .navbar-nav {
        & > .nav-item {
          margin-right: 18px;
          & > .nav-link {
            padding-right: 13px;
            &.dropdown-toggle {
              padding-right: 27px;
              &:before {
                right: 4px;
              }
            }
          }
        }
      }
      .navbar-brand {
        position: relative;
        left: -20px;
      }
      .others-option {
        .option-item {
          margin-right: 20px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1400px) {
  .navbar-area {
    .navbar {
      .navbar-nav {
        & > .nav-item {
          margin-right: 29px;
          & > .nav-link {
            padding-right: 8px;
            &.dropdown-toggle {
              padding-right: 28px;
              &:before {
                right: 4px;
              }
            }
          }
        }
      }
      .navbar-brand {
        position: relative;
        left: -18px;
      }
    }
    .others-option {
      .option-item {
        margin-right: 36px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .navbar-area {
    .container {
      max-width: 1370px;
    }
    .navbar {
      .navbar-nav {
        & > .nav-item {
          margin-right: 24px;
        }
      }
    }
  }
}
@media only screen and (min-width: 1600px) {
  .navbar-area {
    .container {
      max-width: 1520px;
      padding-left: 7px;
      padding-right: 7px;
    }
  }
}
@media only screen and (min-width: 1920px) {
  .navbar-area {
    .container {
      max-width: 1720px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
</style>