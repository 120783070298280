<template>
  <MainNavbar class="style-three" />
  <PageTitle
    pageTitle="Contact Us"
    mainTitle="Contact Us"
    subTitle="A Symphony Of Senses Welcome To Our Table"
  />
  <ContactUs />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MainNavbar from "../components/Layouts/MainNavbar.vue";
import PageTitle from "../components/Common/PageTitle.vue";
import ContactUs from "../components/ContactUs/ContactUs.vue";

export default defineComponent({
  name: "ContactUsPage",
  components: {
    MainNavbar,
    PageTitle,
    ContactUs,
  },
});
</script>