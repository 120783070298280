<template>
  <button
    id="backtotop"
    class="position-fixed text-center border-0 p-0"
    :class="{ active: isTop }"
    @click="scrollToTop()"
  >
    <i class="ri-arrow-up-line"></i>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BackToTop",
  data() {
    return {
      isTop: false,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    window.addEventListener("scroll", () => {
      let scrollPos = window.scrollY;
      if (scrollPos >= 100) {
        this.isTop = true;
      } else {
        this.isTop = false;
      }
    });
  },
});
</script>

<style lang="scss">
#backtotop {
  opacity: 0;
  right: 20px;
  z-index: 99;
  width: 50px;
  bottom: 20px;
  height: 50px;
  font-size: 25px;
  transition: 0.5s;
  line-height: 49px;
  background-color: var(--secondaryColor);
  -webkit-animation: border-transform 10s linear infinite alternate forwards;
  animation: border-transform 10s linear infinite alternate forwards;
  i {
    color: var(--whiteColor);
  }
  &.active {
    opacity: 100%;
  }
}
#backtotop:hover {
  background-color: var(--primaryColor);
  color: var(--whiteColor);
  i {
    color: var(--titleColor);
  }
}

@-webkit-keyframes border-transform {
  0%,
  100% {
    border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
  }
}

@keyframes border-transform {
  0%,
  100% {
    border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
  }
}
</style>