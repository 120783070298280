<template>
  <div class="discount-wrap style-one bg-f">
  </div>
</template>

<script>
export default {
  name: "SetOneGet",
};
</script>

<style lang="scss" scoped>
.discount-wrap {
  &.style-one {
    background-image: url(../../assets/img/discount/discount-bg.webp);
    padding: 185px 0;
    .discount-content {
      background-color: #ab230b;
      border-radius: 50px;
      padding: 38px 40px 39px;
      position: relative;
      z-index: 1;
      .discount-badge {
        position: absolute;
        top: -100px;
        right: -90px;
        z-index: -1;
      }
      .discount-link {
        position: absolute;
        bottom: -21px;
        right: 35px;
        display: inline-flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        width: 58px;
        height: 58px;
        border-radius: 50%;
        background-color: #ffe5a5;
        transition: var(--transition);
        i {
          color: #ed3d33;
          line-height: 0.8;
          display: block;
          margin: 0 auto;
        }
        img {
          transition: var(--transition);
        }
        &:hover {
          background-color: var(--secondaryColor);
          i {
            color: var(--whiteColor);
          }
          img {
            filter: brightness(0) invert(1);
          }
        }
      }
      h4 {
        color: #ffbf35;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 12px;
      }
      .discount-features {
        li {
          color: var(--optionalColor);
          font-size: 24px;
          line-height: 42px;
          position: relative;
          padding-left: 20px;
          &::before {
            position: absolute;
            top: 18px;
            left: 0;
            content: "";
            width: 10px;
            height: 2px;
            background-color: var(--secondaryColor);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .discount-wrap {
    &.style-one {
      padding: 120px 0 90px;
      .discount-content {
        border-radius: 30px;
        padding: 30px 30px 30px;
        .discount-badge {
          top: -60px;
          right: -7px;
          z-index: -1;
          max-width: 160px;
        }
        .discount-link {
          bottom: -21px;
          right: 20px;
          width: 50px;
          height: 50px;
        }
        h4 {
          font-size: 22px;
        }
        .discount-features {
          li {
            font-size: 18px;
            line-height: 36px;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .discount-wrap {
    &.style-one {
      .discount-content {
        position: relative;
        left: -100px;
      }
    }
  }
}

@media only screen and (min-width: 1400px) {
  .discount-wrap {
    &.style-one {
      .discount-content {
        width: calc(100% + 33px);
        margin-left: -18px;
        .discount-badge {
          right: -110px;
        }
      }
    }
    &.style-two {
      .food-card {
        &.style-nine {
          width: calc(100% + 80px);
        }
        &.style-ten {
          width: calc(100% - 80px);
          margin-left: auto;
        }
      }
    }
  }
}
@media only screen and (min-width: 1600px) {
  .discount-wrap {
    &.style-two {
      .container-fluid {
        padding: 0 50px;
      }
    }
  }
}
</style>