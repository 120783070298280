import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PreLoader = _resolveComponent("PreLoader")!
  const _component_ResponsiveNavbar = _resolveComponent("ResponsiveNavbar")!
  const _component_SearchModal = _resolveComponent("SearchModal")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_LightDarkSwtichBtn = _resolveComponent("LightDarkSwtichBtn")!
  const _component_BackToTop = _resolveComponent("BackToTop")!
  const _component_MainFooter = _resolveComponent("MainFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PreLoader),
    _createVNode(_component_ResponsiveNavbar),
    _createVNode(_component_SearchModal),
    _createVNode(_component_router_view),
    _createVNode(_component_LightDarkSwtichBtn),
    _createVNode(_component_BackToTop),
    (_ctx.shouldHideFooter)
      ? (_openBlock(), _createBlock(_component_MainFooter, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}