<template>
  <div class="hero-wrap style-two">
    <div class="container">
     
      <div
        class="hero-img-wrap"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="300"
      >
        <div class="hero-img-card">
          <img src="../../assets/img/hero/hero-img-2.webp" alt="Image" />
        </div>
        <div class="hero-img-card">
          <img src="../../assets/img/hero/hero-img-3.webp" alt="Image" />
          <img src="../../assets/img/hero/hero-img-4.webp" alt="Image" />
        </div>
        <div class="hero-img-card">
          <img src="../../assets/img/hero/hero-img-5.webp" alt="Image" />
        </div>
        <div class="hero-img-card">
          <img src="../../assets/img/hero/hero-img-6.webp" alt="Image" />
          <img src="../../assets/img/hero/hero-img-7.webp" alt="Image" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MenuItems",
};
</script>

<style lang="scss" scoped>
.select-method {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .checkbox {
    margin-right: 38px;
    &:last-child {
      margin-right: 0;
    }
    label {
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0.32px;
      i {
        position: relative;
        top: 4px;
        margin-right: 11px;
        font-size: 26px;
        line-height: 0.8;
        font-weight: 300;
      }
      img {
        margin-right: 8px;
      }
      &:before {
        top: 6px;
        border: 2px solid rgba(255, 229, 165, 0.28);
        width: 17px;
        height: 17px;
        border-radius: 50%;
      }
    }
  }
  .checkbox input:checked + label:after {
    top: 10px;
    left: 4px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: var(--secondaryColor);
  }
}
.hero-wrap {
  &.style-two {
   
    padding: 70px 0 77px;
    .hero-content {
      margin-bottom: 110px;
      .select-method {
        margin-bottom: 100px;
        justify-content: center;
        .checkbox {
          label {
            color: var(--titleColor);
            letter-spacing: 0.04px;
            i {
              color: #ccae79;
            }
            &:before {
              border: 2px solid #ccae79;
            }
          }
        }
        .checkbox input:checked + label:after {
          background: var(--secondaryColor);
        }
      }
      h1 {
        color: var(--titleColor);
        font-size: 85px;
        line-height: 90px;
        font-weight: 800;
        text-align: left;
        margin-bottom: 50px;
        span {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          img {
            position: relative;
          }
          &:first-child {
            img {
              top: -40px;
            }
          }
          &:last-child {
            padding-right: 15px;
            img {
              top: 5px;
            }
          }
        }
      }
    }
    .hero-img-wrap {
      .hero-img-card {
        border-radius: 50px;
        display: block;
        object-fit: cover;
        width: 100%;
        height: 100%;
        img {
          margin-bottom: 23px;
          border-radius: 50px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .hero-wrap {
    &.style-two {
      padding: 100px 0 30px;
      .hero-img-wrap {
        display: block;
        .hero-img-card {
          border-radius: 22px;
          img {
            margin-bottom: 22px;
            border-radius: 15px;
          }
        }
      }
      .hero-content {
        margin-bottom: 30px;
        .select-method {
          margin-bottom: 30px;
          .checkbox {
            margin-right: 18px;
            margin-bottom: 10px;
            label {
              font-size: 14px;
              img {
                position: relative;
                top: -2px;
              }
            }
          }
        }
        h1 {
          font-size: 26px;
          line-height: 40px;
          span {
            font-size: 26px;
            line-height: 40px;
            &:first-child {
              img {
                max-width: 50px;
                top: -10px;
              }
            }
            &:last-child {
              padding-right: 0px;
              text-align: right;
              img {
                top: 5px;
                max-width: 20px;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-wrap {
    &.style-two {
      .hero-img-wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 20px;
        .hero-img-card {
          border-radius: 30px;
          img {
            margin-bottom: 23px;
            border-radius: 30px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
      .hero-content {
        margin-bottom: 70px;
        h1 {
          font-size: 45px;
          line-height: 60px;
          span {
            &:first-child {
              img {
                max-width: 120px;
                top: -20px;
              }
            }
            &:last-child {
              padding-right: 15px;
              img {
                top: 5px;
                max-width: 120px;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 992px) {
  .hero-wrap {
    &.style-two {
      .hero-img-wrap {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        grid-gap: 28px;
      }
    }
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-wrap {
    &.style-two {
      .container {
        max-width: 980px;
      }
      .hero-img-wrap {
        .hero-img-card {
          img {
            border-radius: 30px;
            margin-bottom: 15px;
          }
        }
      }
      .hero-content {
        h1 {
          font-size: 65px;
          line-height: 80px;
          span {
            &:first-child {
              img {
                max-width: 150px;
                top: -20px;
              }
            }
            &:last-child {
              padding-right: 15px;
              img {
                top: 5px;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero-wrap {
    &.style-two {
      .hero-img-wrap {
        .hero-img-card {
          img {
            margin-bottom: 18px;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .hero-wrap {
    &.style-two {
      .container {
        max-width: 1370px;
      }
      .hero-img-wrap {
        .hero-img-card {
          img {
            margin-bottom: 21px;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1400px) {
  .hero-wrap {
    &.style-two {
      .select-method {
        position: relative;
        left: 5px;
        .checkbox {
          label {
            font-weight: 600;
            img {
              position: relative;
              top: -1px;
            }
          }
        }
      }
      .hero-content {
        h1 {
          font-size: 105px;
          line-height: 118px;
          span {
            &:first-child {
              img {
                top: -40px;
              }
            }
            &:last-child {
              padding-right: 58px;
              img {
                top: 5px;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1600px) {
  .hero-wrap {
    &.style-two {
      .container {
        max-width: 1520px;
      }
      .hero-content {
        .select-method {
          left: -37px;
        }
      }
    }
  }
}
@media only screen and (min-width: 1920px) {
  .hero-wrap {
    &.style-two {
      .container {
        max-width: 1720px;
      }
      .hero-content {
        padding: 0 124px 0 201px;
      }
      .hero-img-wrap {
        .hero-img-card {
          img {
            margin-bottom: 23px;
          }
        }
      }
    }
  }
}
</style>