<template>
  <div class="reservation-wrap style-one pt-100">
    <div class="container">
      <div class="row align-items-center pb-100">
        <div class="col-xxl-5 col-lg-5">
          <div class="content-title style-six">
            <h2>Reservation Table?</h2>
            <p>We are open for indoor & outdoor dining.</p>
          </div>
        </div>
        <div class="col-xxl-5 offset-xxl-1 col-lg-6">
          <div class="option-item">
              <router-link to="/contact" class="btn-one">
                Contact us
              </router-link>
            </div>
        </div>
      </div>
      <h2 class="section-subtitle text-center mb-40">
        Any Kind Of <span>Food</span> Made For <span>Our Customer</span>
      </h2>

      <OurCustomer />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import OurCustomer from "./OurCustomer.vue";

export default defineComponent({
  name: "ReservationTable",
  components: {
    OurCustomer,
  },
});
</script>

<style lang="scss" scoped>
.reservation-wrap {
  &.style-one {
    position: relative;
    z-index: 1;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 100%;
      height: 70%;
      background-color: var(--bgColor);
      z-index: -1;
    }
  }
}
.reservation-form {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  input {
    width: calc(100% - 186px);
    margin-right: 15px;
    border: none;
    border-bottom: 1px solid #ccae79;
    color: #ccae79;
    font-size: 15px;
    font-weight: 400;
    line-height: 16px;
    height: 40px;
    letter-spacing: 0.3px;
    padding: 0 0 5px 0;
    &::placeholder {
      color: #ccae79;
    }
  }
  button {
    width: 171px;
    background-color: var(--secondaryColor);
    color: var(--optionalColor);
    border-radius: 50px;
    border: none;
    padding: 17.5px 5px 17.5px;
    color: #ffe5a5;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.32px;
    transition: var(--transition);
    &:hover {
      background-color: var(--titleColor);
      color: var(--whiteColor);
    }
  }
  &.style-two {
    input {
      border-bottom: 1px solid rgba(249, 244, 229, 0.22);
      color: #ffe5a5;
      &::placeholder {
        color: #ffe5a5;
        opacity: 1;
      }
    }
    button {
      background-color: var(--titleColor);
      color: var(--whiteColor);
      color: #ffe5a5;
      &:hover {
        background-color: var(--secondaryColor);
        color: var(--whiteColor);
      }
    }
  }
}

@media only screen and (min-width: 1400px) {
  .reservation-form {
    width: calc(100% - 65px);
    margin-left: auto;
  }
}
</style>