<template>
  <div
    class="responsive-navbar offcanvas offcanvas-end"
    data-bs-backdrop="static"
    tabindex="-1"
    id="navbarOffcanvas"
  >
    <div class="offcanvas-header">
      <a href="/" class="logo d-inline-block">
        <img class="logo-light" src="../../assets/img/logo.png" alt="logo" />
        <img
          class="logo-dark"
          src="../../assets/img/logo-white.png"
          alt="logo"
        />
      </a>
      <button
        type="button"
        class="close-btn"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      >
        <i class="ri-close-line"></i>
      </button>
    </div>
    <div class="offcanvas-body">
      <div class="accordion" id="navbarAccordion">
        <div class="accordion-item">
          <a class="accordion-link without-icon" href="/"> Home </a>
        </div>
        <div class="accordion-item">
          <a class="accordion-link without-icon" href="/about"> About Us </a>
        </div>
        <div class="accordion-item">
          <a class="accordion-link without-icon" href="/menu-classic"> Menu </a>
        </div>
        <div class="accordion-item">
          <a class="accordion-link without-icon" href="/contact"> Conact us </a>
        </div>
      </div>
     
    </div>
  </div>
</template>

<script>
export default {
  name: "ResponsiveNavbar",
};
</script>

<style lang="scss" scoped>
.responsive-navbar {
  border: 0 !important;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--primaryColor);
  .offcanvas-header {
    margin: 22px 22px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 2;
    padding: 0 0 22px 0;
    .close-btn {
      background-color: transparent;
      color: var(--titleColor);
      position: relative;
      font-size: 26px;
      cursor: pointer;
      line-height: 1;
      padding: 0;
      border: 0;
      top: 2px;
      &:hover {
        color: var(--secondaryColor);
      }
    }
    .logo {
      max-width: 120px;
    }
  }
  .offcanvas-body {
    padding: 30px 25px;
    position: relative;
    z-index: 2;
    .accordion {
      .accordion-item {
        border: 0;
        padding: 0;
        border-radius: 0;
        margin-bottom: 20px;
        color: var(--titleColor);
        background-color: transparent;
        box-shadow: none;
        &:last-child {
          margin-bottom: 0;
        }
        .accordion-button {
          width: 100%;
          display: block;
          box-shadow: unset;
          position: relative;
          padding: 0 18px 0 0;
          color: var(--titleColor);
          background-color: transparent;
          transition: var(--transition);
          font-weight: 600;
          box-shadow: none;
          &.without-icon {
            &:before {
              display: none;
            }
          }
          &:before {
            transform: translateY(-50%);
            position: absolute;
            margin-top: -1px;
            line-height: 1;
            right: -2px;
            top: 50%;
            content: "\ea4d";
            font-family: remixicon !important;
            font-size: 19px;
            font-weight: 400;
            color: var(--secondaryColor);
          }
          &:after {
            display: none;
          }
          &:hover,
          &.active {
            color: var(--secondaryColor);
          }
        }
        .accordion-link {
          font-weight: 500;
        }
        .accordion-collapse {
          .accordion-body {
            padding: 20px;
            margin-top: 12px;
            border: none;
            background-color: var(--whiteColor);
            .accordion-item {
              color: var(--titleColor);
              margin-bottom: 15px;
              border-bottom: none;
              padding-bottom: 0;
              &:last-child {
                margin-bottom: 0;
              }
              .accordion-link {
                width: 100%;
                display: block;
                color: var(--titleColor);
                background-color: transparent;
                font-size: 15px;
                font-weight: 500;
                &:hover,
                &.active {
                  color: var(--secondaryColor);
                }
              }
              .accordion-button {
                width: 100%;
                display: block;
                box-shadow: unset;
                position: relative;
                padding: 0 18px 0 0;
                background-color: transparent;
                font-size: 15px;
                font-weight: 500;
                &:after {
                  display: none;
                }
              }
              .accordion-collapse {
                .accordion-body {
                  background-color: var(--primaryColor);
                  .accordion-item {
                    .accordion-link {
                      color: var(--titleColor);
                      &:hover,
                      &.active {
                        color: var(--secondaryColor);
                      }
                    }
                  }
                }
                .accordion-item {
                  .accordion-collapse {
                    .accordion-body {
                      background-color: transparent;
                    }
                  }
                }
              }
            }
          }
        }
        .accordion-link {
          color: var(--titleColor);
          &.active,
          &:hover {
            color: var(--secondaryColor);
          }
        }
      }
    }
    .offcanvas-contact-info {
      margin-top: 30px;
      padding-top: 30px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      h4 {
        margin-bottom: 25px;
        font-weight: 600;
        color: var(--titleColor);
      }
    }
    .contact-info {
      margin-top: 10px;
      li {
        position: relative;
        margin-bottom: 12px;
        color: var(--titleColor);
        padding-left: 28px;
        &:last-child {
          margin-bottom: 0;
        }
        i {
          position: absolute;
          top: 9px;
          left: 0;
          transition: var(--transition);
          font-size: 19px;
          line-height: 0.6;
          margin: 0 auto;
          color: var(--secondaryColor);
        }
        span {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          justify-content: center;
          width: 36px;
          height: 36px;
          border-radius: 50%;
          background-color: rgba(255, 255, 255, 0.5);
          margin-right: 10px;
          transition: var(--transition);
        }
        p,
        a {
          color: var(--titleColor);
          transition: var(--transition);
          display: block;
          margin: 0;
        }
        a {
          &:hover {
            color: var(--secondaryColor);
          }
        }
        &:hover {
          span {
            background-color: var(--secondaryColor);
            i {
              color: var(--titleColor);
            }
          }
        }
      }
    }
    .social-profile {
      margin-top: 30px;
      margin-bottom: 30px;
      li {
        margin: 0 5px 0 0;
        a {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          justify-content: center;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: var(--secondaryColor);
          i {
            color: var(--whiteColor);
            font-size: 20px;
            line-height: 0.8;
            display: block;
            margin: 0 auto;
          }
          &:hover {
            background-color: var(--titleColor);
          }
        }
      }
    }
    .secondary-menu {
      margin-bottom: 20px;
    }
  }
}
</style>