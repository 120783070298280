<template>
  <div class="combo-wrap style-one pt-100 pb-70">
    <div class="container">
      <h6 class="section-subtitle text-center mb-40">
        25 Years Of <span>Experience</span> In Restaurant
        <span>Services In Qatar</span>
      </h6>
      <div class="row justify-content-center gx-xxl-35">
        <div
          class="col-xxl-3 col-xl-4 col-md-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="200"
        >
          <div class="combo-card style-one">
            <h5>It Tastes Great!</h5>
            <div class="service-amt">
              
              <h4>10k</h4>
              <p>More Customers We Served Daily</p>
            </div>
          </div>
        </div>
        <div
          class="col-xxl-3 col-xl-4 col-md-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="400"
        >
          <div class="combo-card style-two">
            <div class="combo-title">
              <h3><router-link to="/menu-classic">Main Dishes</router-link></h3>
              <div class="combo-img">
                <img src="../../assets/img/combo/combo-1.webp" alt="Image" />
              </div>
            </div>
            <div class="combo-amt">
              <h2><router-link to="/menu-classic">20+ Items</router-link></h2>
              <p>The test of Joy.</p>
            </div>
          </div>
        </div>
        <div
          class="col-xxl-3 col-xl-4 col-md-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="600"
        >
          <div class="combo-card style-two">
            <div class="combo-title">
              <h3><router-link to="/menu-classic">Mojitos</router-link></h3>
              <div class="combo-img">
                <img src="../../assets/img/combo/combo-2.webp" alt="Image" />
              </div>
            </div>
            <div class="combo-amt">
              <h2><router-link to="/menu-classic">10+ Items</router-link></h2>
              <p>The test of Joy.</p>
            </div>
          </div>
        </div>
        <div
          class="col-xxl-3 col-xl-4 col-md-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="800"
        >
          <div class="combo-card style-two">
            <div class="combo-title">
              <h3><router-link to="/menu-classic">Hot Coffees</router-link></h3>
              <div class="combo-img">
                <img src="../../assets/img/combo/combo-3.webp" alt="Image" />
              </div>
            </div>
            <div class="combo-amt">
              <h2><router-link to="/menu-classic">6+ Items</router-link></h2>
              <p>The test of Joy.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExperienceInRestaurantServices",
};
</script>

<style lang="scss" scoped>
.combo-wrap {
  &.style-one {
    background-image: url(../../assets/img/bg-2.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.combo-card {
  margin-bottom: 30px;
  min-height: 338px;
  &.style-one {
    background-color: #5A633D;
    padding: 47px 35px 40px 45px;
    border-radius: 30px;
    h5 {
      color: #FFE5A5;
      font-size: 20px;
      font-weight: 400;
      letter-spacing: 0.2px;
    }
    .service-amt {
      position: relative;
      padding: 55px 0 62px 0px;
      img {
        position: absolute;
        bottom: 2px;
        left: -15px;
      }
      h4 {
        color: #FFE5A5;
        font-size: 50px;
        font-weight: 700;
        margin-bottom: 0;
      }
      p {
        font-size: 14px;
        line-height: 22px;
        font-weight: 200;
        letter-spacing: 0.28px;
        margin-bottom: 0;
        color: #FFE5A5;
      }
    }
  }
  &.style-two {
    background-color: var(--whiteColor);
    border-radius: 30px;
    padding: 31px 35px 35px;
    .combo-title {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 75px;
      h3 {
        font-size: 20px;
        font-weight: 500;
        position: relative;
        width: calc(100% - 140px);
        margin-right: 15px;
        margin-bottom: 0;
        &:after {
          position: absolute;
          bottom: -32px;
          left: 0;
          content: "";
          width: 100%;
          height: 10px;
          background: radial-gradient(
            54.36% 59.6% at 44.84% 96.85%,
            #ffb4b4 0%,
            rgba(251, 171, 15, 0.24) 73.48%,
            rgba(244, 126, 34, 0) 100%
          );
        }
        a {
          color: var(--titleColor);
          &:hover {
            color: var(--secondaryColor);
          }
        }
      }
      .combo-img {
        width: 124px;
        height: 124px;
        border-radius: 50%;
        img {
          border-radius: 50%;
        }
      }
    }
    .combo-amt {
      h2 {
        font-size: 40px;
        font-weight: 500;
        letter-spacing: -1.6px;
        background: radial-gradient(
          85% 85% at 50.38% 35%,
          #5A633D 40.33%,
          #5A633D 84.02%,
          #5A633D 100%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 0;
      }
      p {
        margin-bottom: 0;
        font-size: 14px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .combo-card {
    &.style-one {
      padding: 37px 25px 45px 25px;
    }
    &.style-two {
      padding: 31px 25px 35px;
    }
  }
}
</style>