import { createWebHistory, createRouter, RouteRecordRaw } from "vue-router";
import HomeOnePage from "../pages/HomeOnePage.vue";
import AboutUsPage from "../pages/AboutUsPage.vue";
import ContactUsPage from "../pages/ContactUsPage.vue";
import ErrorPage from "../pages/ErrorPage.vue";
import MenuClassicPage from "../pages/MenuClassicPage.vue";


const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "HomeOnePage",
    component: HomeOnePage,
  },
  {
    path: "/about",
    name: "AboutUsPage",
    component: AboutUsPage,
  },
  {
    path: "/contact",
    name: "ContactUsPage",
    component: ContactUsPage,
  },
  
  { path: "/:pathMatch(.*)*", name: "ErrorPage", component: ErrorPage },
  {
    path: "/menu-classic",
    name: "MenuClassicPage",
    component: MenuClassicPage,
  },
  

];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

export default router;