<template>
  <MainNavbar class="style-three" />
  <div class="error-wrap ptb-100">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <div class="error-content">
            <img src="../assets/img/404.webp" alt="Iamge" />
            <h2>Oops! Page Not Found</h2>
            <p>
              The page you are looking for might have been removed had its name
              changed or is temporarily unavailable.
            </p>
            <router-link to="/" class="btn-one">Back To home</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MainNavbar from "../components/Layouts/MainNavbar.vue";

export default defineComponent({
  name: "ErrorPage",
  components: {
    MainNavbar,
  },
});
</script>

<style lang="scss" scoped>
.error-wrap {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.error-content {
  text-align: center;
  img {
    display: block;
    margin: 0 auto 30px;
    max-width: 600px;
  }

  p {
    margin: 0 auto 20px;
    max-width: 520px;
  }
}
@media only screen and (max-width: 767px) {
  .error-content {
    img {
      max-width: 100%;
    }
  }
}
</style>