<template>
  <div class="hero-wrap style-one">
    <router-link class="hero-centered-text bounce" to="/about">
      <img src="../../assets/img/hero/hero-shape-1.webp" alt="Image" />
      Want To know More About Us?
    </router-link>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="hero-content">
            <span
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              Quick <b>Bites</b>, Unmatched <b>Delights</b>
            </span>
            <h1
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="400"
            >
              Savor the flavors that
tell a story. Join us at
thetinyleaves
            </h1>
            <router-link
              to="/menu-classic"
              class="btn-two"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="600"
            >
              Start Order
              <img src="../../assets/img/icons/arrow.svg" alt="Image" />
            </router-link>
            <div
              class="select-method"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="800"
            >
              <div class="checkbox">
                <input type="radio" id="test1" name="radio-group" checked />
                <label for="test1">
                  <img
                    src="../../assets/img/icons/delivery-2.svg"
                    alt="Image"
                  />
                  Delivery
                </label>
              </div>
              <div class="checkbox">
               
                <label for="test2">
                  <img
                    src="../../assets/img/icons/takeaway-2.svg"
                    alt="Image"
                  />
                  Takeaway
                </label>
              </div>
              <div class="checkbox">
                <input type="radio" id="test3" name="radio-group" />
                <label for="test3">
                  <img src="../../assets/img/icons/dine-in-2.svg" alt="Image" />
                  Dine-in
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div
            class="hero-img-wrap"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="200"
          >
           
            <img
              src="../../assets/img/hero/hero-img-1.webp"
              alt="Image"
              class="hero-img"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainBanner",
};
</script>

<style lang="scss" scoped>
.select-method {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .checkbox {
    margin-right: 38px;
    &:last-child {
      margin-right: 0;
    }
    label {
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0.32px;
      i {
        position: relative;
        top: 4px;
        margin-right: 11px;
        font-size: 26px;
        line-height: 0.8;
        font-weight: 300;
      }
      img {
        margin-right: 8px;
      }
      &:before {
        top: 6px;
        border: 2px solid rgba(255, 229, 165, 0.28);
        width: 17px;
        height: 17px;
        border-radius: 50%;
      }
    }
  }
  .checkbox input:checked + label:after {
    top: 10px;
    left: 4px;
    display:none;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: var(--secondaryColor);
  }
}
.hero-wrap {
  &.style-one {
    background-image: url(../../assets/img/hero/hero-bg.webp);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 135px 0 170px;
    position: relative;
    .hero-centered-text {
      position: absolute;
      bottom: 80px;
      left: 45%;
      transform: translateX(-50%);
      max-width: 160px;
      text-align: center;
      color: var(--optionalColor);
      display: inline-block;
      font-weight: 300;
      text-decoration-line: underline;
      transition: var(--transition);
      &:hover {
        color: var(--secondaryColor);
      }
      img {
        display: block;
        margin: 0 auto 7px;
        position: relative;
        left: 10px;
      }
    }
    .hero-content {
      span {
        color: var(--optionalColor);
        font-size: 18px;
        font-weight: 300;
        line-height: 15px;
        letter-spacing: 0.36px;
        display: block;
        margin-bottom: 12px;
        b {
          color: #A4AE46;
          font-weight: 700;
        }
      }
      h1 {
        color: var(--optionalColor);
        font-size: 64px;
        font-weight: 400;
        line-height: 75px;
        margin-bottom: 27px;
      }
      .btn-two {
        img {
          margin-left: 10px;
          margin-right: 0;
        }
      }
      .select-method {
        margin-top: 25px;
        .checkbox {
          label {
            color: #ccae79;
            i {
              color: #ccae79;
            }
            &:before {
              border: 2px solid rgba(255, 229, 165, 0.28);
            }
          }
        }
        .checkbox input:checked + label:after {
          background: var(--secondaryColor);
        }
        .checkbox input:checked + label:before {
          border-color: #ffe5a5;
        }
      }
    }
    .hero-img-wrap {
      position: relative;
      z-index: 1;
      .hero-img {
        display: block;
        margin: 0 0 0 auto;
      }
      .discount-badge {
        position: absolute;
        top: 8px;
        left: 22.5%;
        z-index: 1;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .hero-wrap {
    &.style-one {
      padding: 100px 0 210px;
      .hero-centered-text {
        bottom: 100px;
        img {
          max-width: 50px;
        }
      }
      .hero-content {
        & > span {
          font-size: 14px;
        }
        h1 {
          font-size: 32px;
          line-height: 42px;
        }
        .select-method {
          margin-top: 15px;
          .checkbox {
            margin-right: 18px;
            margin-bottom: 10px;
            label {
              font-size: 14px;
              img {
                position: relative;
                top: -2px;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-wrap {
    &.style-one {
      padding: 135px 0 210px;
      .hero-centered-text {
        bottom: 100px;
        img {
          max-width: 50px;
        }
      }
      .hero-content {
        h1 {
          font-size: 48px;
          line-height: 58px;
        }
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  .hero-wrap {
    &.style-one {
      .hero-img-wrap {
        margin-top: 30px;
      }
    }
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-wrap {
    &.style-one {
      .hero-content {
        h1 {
          font-size: 48px;
          line-height: 58px;
        }
        .select-method {
          .checkbox {
            margin-right: 20px;
            label {
              color: #ccae79;
              i {
                color: #ccae79;
              }
              &:before {
                border: 2px solid rgba(255, 229, 165, 0.28);
              }
            }
          }
          .checkbox input:checked + label:after {
            background: var(--secondaryColor);
          }
          .checkbox input:checked + label:before {
            border-color: #ffe5a5;
          }
        }
      }
      .hero-img-wrap {
        position: relative;
        z-index: 1;
        .hero-img {
          display: block;
          margin: 0 0 0 auto;
        }
        .discount-badge {
          position: absolute;
          top: 8px;
          left: 22.5%;
          z-index: 1;
        }
      }
    }
  }
}
@media only screen and (min-width: 1400px) {
  .hero-wrap {
    &.style-one {
      .container {
        max-width: 1370px;
      }
      .hero-img-wrap {
        position: relative;
        top: 10px;
        padding-left: 50px;
      }
      .hero-content {
        h1 {
          font-size: 78px;
          line-height: 88px;
        }
      }
    }
  }
}
@media only screen and (min-width: 1600px) {
  .hero-wrap {
    &.style-one {
      .container {
        max-width: 1520px;
      }
    }
  }
}
@media only screen and (min-width: 1920px) {
  .hero-wrap {
    &.style-one {
      .container {
        max-width: 1720px;
      }
      .hero-centered-text {
        bottom: 180px;
      }
      .hero-img-wrap {
        padding-left: 0;
      }
      .hero-content {
        h1 {
          font-size: 103px;
          line-height: 125px;
          font-weight: 400;
        }
      }
    }
  }
}
</style>